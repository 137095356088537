import { useQueryClient } from '@tanstack/vue-query';
import { createSharedComposable } from '@vueuse/core';
import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router/composables';
import {
    useGetAlertConfigurations,
    useGetAlertStatus,
    useUpdateAlertConfiguration,
} from '@/composables/queries/useAlertQueries';
import { useAbility } from '@/composables/useAbility';
import { useFolders } from '@/composables/useFolders';
import { useView as useGlobalView } from '@/composables/useView';

export const viewModes = {
    // Display only ongoing and active alerts
    ONGOING: 'ongoing',
    // Display all alert configurations (with last status)
    ALL: 'all',
    // Display the history of alerts
    HISTORY: 'history',
};

export const useView = createSharedComposable(() => useGlobalView('alerts', 'table', ['table', 'tree']));
export const useTab = () => {
    const route = useRoute();
    const viewMode = computed(() => route.meta.viewMode);
    const isViewHistory = computed(() => viewMode.value === viewModes.HISTORY);
    const isViewAll = computed(() => viewMode.value === viewModes.ALL);

    return {
        viewMode,
        isViewHistory,
        isViewAll,
    };
};

export const useAlertFolders = createSharedComposable(() => {
    const { data: alertConfigurations, isPending } = useGetAlertConfigurations();
    const { mutateAsync: updateAlertConfiguration } = useUpdateAlertConfiguration();
    const queryClient = useQueryClient();

    const { isPendingFolders, ...rest } = useFolders({
        context: 'alertConfigurations',
        items: computed(() =>
            (alertConfigurations.value || []).map((alertConfiguration) => ({
                type: 'alertConfiguration',
                ...alertConfiguration,
            })),
        ),
        redirectToAfterCreate: (id) => ({ name: 'alerts-configurations', query: { id } }),
        onDeleteFolder: () => {
            queryClient.invalidateQueries({ queryKey: ['alertConfigurations'] });
        },
        moveItem: updateAlertConfiguration,
    });

    return {
        isPendingTree: computed(() => isPending.value || isPendingFolders.value),
        ...rest,
    };
});

export const useTabAlertStatus = () => {
    const route = useRoute();
    const { isViewHistory } = useTab();
    return useGetAlertStatus(
        computed(() => {
            if (isViewHistory.value) {
                return {
                    startDate_gte: route.query.startDate,
                    endDate_lt: route.query.endDate,
                };
            }
            return {
                isPending: true,
            };
        }),
    );
};

export const useSelectedAlertStatus = () => {
    const route = useRoute();
    const { isViewAll } = useTab();
    const { data: alertStatus } = useTabAlertStatus();

    return computed(() => {
        if (isViewAll.value) {
            return alertStatus.value?.find(({ alertConfigurationId }) => alertConfigurationId === route.query.id);
        }
        return alertStatus.value?.find(
            ({ alertConfigurationId, startDate }) =>
                alertConfigurationId === route.query.id && startDate === route.query.date,
        );
    });
};

export const usePresetParentFolder = (item) => {
    const { can, subject } = useAbility();
    const { view } = useView();
    const { parentFolder, lastExpandedItemId } = useAlertFolders();
    const itemWithParentId = ref(item);
    watch(
        parentFolder,
        (newParentFolder, oldParentFolder) => {
            if (!newParentFolder || oldParentFolder || view.value !== 'tree' || !lastExpandedItemId.value) {
                return;
            }

            if (can('update-children', subject('Folder', newParentFolder))) {
                itemWithParentId.value = { ...item.value, parentId: newParentFolder.id };
            }
        },
        { immediate: true },
    );

    return itemWithParentId;
};
