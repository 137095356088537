import { omit } from 'lodash';
import moment from 'moment-timezone';
import { getRelativeDateRange } from '@/utils/date-ranges.utils';
import { energiencyColors } from '@/helpers/colors';

export const WIDGETS_LIST = [
    {
        title: 'WIDGET_TIMESERIES_CHART_TITLE',
        type: 'timeseries',
        icon: 'timeseries-chart-colored',
        description: 'WIDGET_TIMESERIES_CHART_TITLE',
    },
    {
        title: 'WIDGET_TIMESERIES_TABLE_TITLE',
        type: 'timeseries-table',
        icon: 'timeseries-table-colored',
        description: 'WIDGET_TIMESERIES_TABLE_TITLE',
    },
    {
        title: 'WIDGET_SCATTER_PLOT_TITLE',
        type: 'scatter',
        icon: 'scatter-plot-colored',
        description: 'WIDGET_SCATTER_PLOT_TITLE',
    },
    {
        title: 'WIDGET_PROPORTION_CHART_TITLE',
        type: 'proportion',
        icon: 'proportion-chart-colored',
        description: 'WIDGET_PROPORTION_CHART_TITLE',
    },
    {
        title: 'WIDGET_WATERFALL_CHART_TITLE',
        type: 'waterfall-chart',
        icon: 'waterfall-chart-colored',
        decription: 'WIDGET_WATERFALL_CHART',
    },
    {
        title: 'WIDGET_STATISTICS_CHART_TITLE',
        type: 'statistics-chart',
        icon: 'statistics-chart-colored',
        description: 'WIDGET_STATISTICS_CHART_TITLE',
    },
    {
        title: 'WIDGET_STATISTIC_TITLE',
        type: 'statistic',
        icon: 'statistic-card-colored',
        description: 'WIDGET_STATISTICS_TITLE',
    },
    {
        title: 'WIDGET_STATISTICS_TABLE_TITLE',
        type: 'statistics-table',
        icon: 'statistics-table-colored',
        description: 'WIDGET_STATISTICS_TABLE_TITLE',
    },
    {
        title: 'WIDGET_CORRELATION_MATRIX_TITLE',
        type: 'correlation-matrix',
        icon: 'correlation-matrix-colored',
        description: 'WIDGET_CORRELATION_MATRIX_TITLE',
    },
    {
        title: 'WIDGET_TEXT_AND_IMAGES_TITLE',
        type: 'text',
        icon: 'text-and-images-colored',
        description: 'WIDGET_TEXT_AND_IMAGES_TITLE',
    },
    {
        title: 'WIDGET_SYNOPTIC_TITLE',
        type: 'synoptic',
        icon: 'synoptic-colored',
        description: 'WIDGET_SYNOPTIC_TITLE',
    },
    {
        title: 'WIDGET_SANKEY_TITLE',
        type: 'sankey',
        icon: 'sankey-colored',
        description: 'WIDGET_SANKEY_TITLE',
    },
    {
        title: 'WIDGET_SPEEDOMETER_TITLE',
        type: 'speedometer',
        icon: 'speedometer-gauge-colored',
        description: 'WIDGET_SPEEDOMETER_TITLE',
    },
    {
        title: 'WIDGET_HEATMAP_TITLE',
        type: 'heatmap',
        icon: 'heatmap-colored',
        description: 'WIDGET_HEATMAP_TITLE',
    },
    {
        title: 'WIDGET_HISTOGRAM_TITLE',
        type: 'histogram',
        icon: 'histogram-colored',
        description: 'WIDGET_HISTOGRAM_TITLE',
    },
];

export function getDefaultConfig(type) {
    switch (type) {
        case 'text':
            return {
                data: {
                    content: '',
                    series: [],
                },
            };
        case 'heatmap':
        case 'scatter':
        case 'statistics-chart':
            return {
                data: {
                    xAxis: {},
                    yAxis: {},
                },
            };
        case 'histogram':
        case 'waterfall-chart':
            return {
                data: {
                    xAxis: {},
                    yAxis: {
                        statistic: 'sum',
                    },
                },
            };
        case 'speedometer':
            return {
                data: {},
            };
        case 'statistic':
            return {
                data: {},
                options: { showUnit: true },
            };
        case 'proportion':
        case 'correlation-matrix':
        case 'sankey':
        case 'synoptic':
        case 'timeseries-table':
            return {
                data: { series: [] },
            };
        case 'statistics-table':
            return {
                data: { series: [], statistics: [] },
            };
        case 'timeseries':
            return {
                data: {
                    yAxis: {},
                    y2Axis: {},
                    comparisons: [],
                },
            };
    }
}

export const DATE_RANGE_TYPES = { ABSOLUTE: 'absolute', RELATIVE: 'relative' };

export const RELATIVE_DATE_RANGES = {
    TODAY: { unit: 'day', offset: 0 },
    YESTERDAY_AND_TODAY: { unit: 'day', offset: [-1, 0] },
    TODAY_AND_TOMORROW: { unit: 'day', offset: [0, 1] },
    YESTERDAY: { unit: 'day', offset: -1 },
    LAST_SEVEN_DAYS: { unit: 'day', offset: [-6, 0] },
    LAST_TWELVE_MONTHS: { unit: 'month', offset: [-12, 0] },
    CURRENT_WEEK: { unit: 'week', offset: 0 },
    LAST_WEEK: { unit: 'week', offset: -1 },
    CURRENT_MONTH: { unit: 'month', offset: 0 },
    LAST_MONTH: { unit: 'month', offset: -1 },
    CURRENT_YEAR: { unit: 'year', offset: 0 },
    LAST_YEAR: { unit: 'year', offset: -1 },
};

export const DEFAULT_FONT = 'Avenir LT Std';

export const DASHBOARD_FONTS = {
    Arial: `Arial, Arimo, ${DEFAULT_FONT}`,
    'Avenir LT Std': DEFAULT_FONT,
    Montserrat: `Montserrat, ${DEFAULT_FONT}`,
    'Open Sans': `Open Sans, ${DEFAULT_FONT}`,
    Roboto: `Roboto, ${DEFAULT_FONT}`,
    'Source Sans Pro': `Source Sans Pro, ${DEFAULT_FONT}`,
};

export const IS_DRAFT_OPTIONS = [
    {
        label: 'PUBLISHED_TEXT',
        value: false,
        icon: { name: 'fas circle', color: energiencyColors.green },
    },
    {
        label: 'DRAFT_TEXT',
        value: true,
        icon: { name: 'wrench', color: energiencyColors.orange },
    },
];

// Deprecated General properties to pick for a meter
export const METER_PROPERTIES = ['meterId', 'unit', 'decimals', 'timestep', 'aggregationPolicy', 'quantityType'];
// Deprecated General properties to pick for a formula
export const FORMULA_PROPERTIES = ['id', 'formula', 'timestep', 'aggregationPolicy', 'quantityType'];

/**
 * Get the date range from widget's date range configuration (timezone is the same as dashboard's date range)
 * @param {Object} config   Widget's date range configuration
 * @param {{startDate: string, endDate: string}} dashboardDateRange Dashboard's date range
 * @param {string} timezone Timezone of the dates
 * @returns {{startDate: string, endDate: string}} startDate and endDate to ISO 8061 string
 */
export function getWidgetDateRange(config, dashboardDateRange, timezone) {
    if (!config) {
        return dashboardDateRange;
    }
    let { startDate, endDate } = dashboardDateRange;

    const { unit, offset } = config;
    switch (config.type) {
        case 'before':
            endDate = moment(startDate).tz(timezone).subtract(1, 'ms').toISOString();
            startDate = moment(startDate).tz(timezone).subtract(1, unit).toISOString();
            break;
        case 'full':
            startDate = moment(startDate).tz(timezone).startOf(unit).toISOString();
            endDate = moment(startDate).tz(timezone).endOf(unit).toISOString();
            break;
        case 'since-start':
            startDate = moment(startDate).tz(timezone).startOf(unit).toISOString();
            break;
        case DATE_RANGE_TYPES.RELATIVE:
            return getRelativeDateRange(offset, unit, timezone);
        default:
            startDate = config.startDate;
            endDate = config.endDate;
    }
    return { startDate, endDate };
}

/**
 * remove all the property which concern a dataset used for widget with single dataset
 * @param {Object} value
 * @returns {Object} reset dataset related property
 */
export function resetDatasetProperties(value) {
    return omit(value, [
        'color',
        'view',
        'type',
        'title',
        'lineStyle',
        'lineTension',
        'lineWidth',
        'variableId',
        ...METER_PROPERTIES,
        ...FORMULA_PROPERTIES,
    ]);
}

export function isCustomerTemplate(dashboard) {
    return dashboard?.template === 'customer';
}

export function isLibraryTemplate(dashboard) {
    return dashboard?.template === 'library';
}

export function isReportTemplate(dashboard) {
    return dashboard?.scheduling?.enabled;
}
