import { saveAs } from 'file-saver';
import moment from 'moment-timezone';

/**
 * Launch file download in the browser. We create a hidden link and click it.
 * @param {string} filename
 * @param {string} fileContent
 */
export function downloadFile(filename, fileContent) {
    const blob = new Blob([fileContent], { type: 'text/csv;charset=UTF-8', endings: 'transparent' });
    saveAs(blob, filename);
}

/**
 * Download a file from an URL. We create a hidden link and click it
 * @param {string} url
 * @param {string} filename
 */
export function downloadFromUrl(url, filename) {
    saveAs(url, filename);
}

/**
 * Date format used in CSV file name.
 */
const FILE_NAME_DATE_FORMAT = 'DD-MM-YYYY_HH-mm';

/**
 * Returns a valid filename prefixed with a date and time.
 *
 * @param {string} name
 * @param {string} extension
 * @return {string}
 */
export function getFilenameWithDateTime(name, extension = 'csv') {
    const dateString = moment().format(FILE_NAME_DATE_FORMAT);
    return `${dateString}_${name.replace(/[\s/\\:?!]/g, '_')}.${extension}`;
}

/**
 * Partition object properties
 * @param {Object} object
 * @param {Function} predicate
 * @returns {Array}
 */
export function partitionObject(object, predicate) {
    return Object.entries(object).reduce(
        (res, [key, value]) => {
            const index = predicate(value) ? 0 : 1;
            res[index][key] = value;
            return res;
        },
        [{}, {}],
    );
}

/**
 * True if File, otherwise false
 * @param {*} value
 * @returns {boolean}
 */
export function isFile(value) {
    return value instanceof File || Object.prototype.toString.call(value) === '[object File]';
}
