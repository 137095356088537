import axios from 'axios';
import _ from 'lodash';
import api, { getAbortController } from './api.config';
import { getPoints } from './points.service';

const abortControllers = {};

/**
 * Return the timeseries comparisons resource URL.
 *
 * @param {string} customerCode Customer code
 * @return {string} URL
 */
function aggregationsUrl(customerCode) {
    return `/timeseries/v2/${customerCode}/timeseries/aggregations`;
}

/**
 * Retrieve timeseries data with comparison from the REST API.
 *
 * @param {Object} params
 * @param {string} params.customerCode Customer code
 * @param {Array}  params.dateRanges   List of date ranges (the first being the current range, the others
 *                                     being the comparison ranges)
 * @param {Array}  params.meters       List of meters (meterId, unit as symbol)
 * @param {string} params.timestep     Timestep as ISO-8601 duration
 * @param {string} params.format       Format ("json" or "csv")
 * @param {string} params.lang         Language to use for column headers
 * @param {Object} params.csvOptions   CSV options
 * @return {Promise<Array|string>} Timeseries data
 */
export async function getTimeseries({
    dashboardId,
    shareLinkId,
    customerCode,
    dateRanges = [],
    meters = [],
    timestep = 'P0D',
    format = 'json',
    lang = 'en',
    csvOptions = {},
    groupBy,
    timezone,
    filterNullValues = false,
    monotonous,
    cancellable = false,
}) {
    if (dateRanges.length === 0 || meters.length === 0) {
        return [];
    }
    try {
        abortControllers.get = getAbortController(abortControllers.get);
        const response = await api.post(
            aggregationsUrl(customerCode),
            {
                dashboardId,
                dateRanges,
                meters: meters,
                timestep,
                csvOptions,
                groupBy,
                timezone,
                filterNullValues,
                monotonous,
            },
            {
                headers: {
                    Accept: format === 'csv' ? 'text/csv' : 'application/json',
                    'Accept-Language': lang,
                    'Content-Type': 'application/json',
                },
                ...(cancellable && { signal: abortControllers.get.signal }),
                params: {
                    shareLinkId,
                },
            },
        );

        return response.data;
    } catch (error) {
        if (!axios.isCancel(error)) {
            throw error.response;
        }
    }
}

/**
 * Retrieve raw points data or aggregated data of a meter.
 *
 * @param {Object} params
 * @param {string} params.customerCode Customer code
 * @param {string} params.startDate    Start date to retrieve import jobs (as ISO8601) (included)
 * @param {string} params.endDate      End date to retrieve import jobs (as ISO8601) (excluded)
 * @param {Meter[]} params.meters      An Array of meters
 * @param {string} params.timestep     Timestep duration as ISO-8601 string
 * @param {string} params.timezone     Timezone of the data
 * @param {string} params.format       Format ("json" or "csv")
 * @param {string} params.lang         Language to use for column headers
 * @param {Object} params.csvOptions   CSV options
 * @return {Promise<Array|string>} data
 */
export async function getDataByDateRange({
    customerCode,
    startDate,
    endDate,
    meters,
    timestep,
    timezone,
    groupBy,
    filterNullValues,
    format = 'json',
    lang = 'en',
    csvOptions = {},
}) {
    if (timestep === 'P0D') {
        const metersId = _.uniq(
            meters?.filter((m) => !m.formula && !m.virtual).map(({ id, meterId }) => id ?? meterId),
        ).join(',');
        return getPoints({
            customerCode,
            filters: { meterId_in: metersId, date_gte: startDate, date_lt: endDate },
            format,
            lang,
            timezone,
        });
    }

    return getTimeseries({
        meters: meters,
        dateRanges: [{ startDate, endDate }],
        format,
        lang,
        groupBy,
        filterNullValues,
        customerCode,
        timestep,
        csvOptions,
        timezone,
    });
}
