import { default as i18next, t } from 'i18next';
import _ from 'lodash';
import { arrayToTree } from 'performant-array-to-tree';
import {
    createPerimeterElement,
    deletePerimeterElement,
    getPerimeter,
    getPerimeterElement,
    updatePerimeterElement,
} from '@/api/perimeter.service';
import { getDateRange, upsertPoints } from '@/api/points.service';
import { getDataByDateRange } from '@/api/timeseries.service';
import { downloadFile, getFilenameWithDateTime } from '@/utils/download.utils';
import { formulaValidationCache } from '@/utils/formula.utils';
import { close as closeNotification, notify } from '@/helpers/notifications';
import { dateRangeActionTypes, dateRangeGetters } from '@/store/getters.utils';
import { NAMESPACE as NS_DATA_TYPES } from '@/store/modules/data-types';
import { NAMESPACE as NS_SETTINGS } from '@/store/modules/settings';
import dataModule, { mutationTypes as dataMutationTypes } from '@/store/reusable-modules/data-list.module';
import queryParamsModule from '@/store/reusable-modules/query-params.module';
import { addEdition } from '@/store/utils/edition.utils';
import {
    ADD_POINT,
    APPLY_ZOOM,
    FETCH_METER_TIMESERIES_CSV,
    GO_TO_CREATE_ITEM,
    GO_TO_ITEM,
    GO_TO_ITEMS,
    GO_TO_ITEM_CONFIGURATION,
    MOVE_ITEM,
    UPDATE_ITEM,
} from './action-types';
import {
    ADDING_POINT,
    ADD_POINT_ERROR,
    ADD_POINT_SUCCESS,
    FETCHING_ITEM_TIMESERIES_CSV,
    FETCH_ITEM_TIMESERIES_CSV_ERROR,
    FETCH_ITEM_TIMESERIES_CSV_SUCCESS,
    SET_LAST_SELECTED_ITEM_ID,
    UPDATE_ITEM_SUCCESS,
    ZOOM_APPLIED,
    ZOOM_RESET,
} from './mutation-types';

export const NAMESPACE = 'meters';

const perimeterTypes = {
    METER: 'meter',
    SITE: 'site',
    FOLDER: 'folder',
};

const TYPES_ORDER = [perimeterTypes.SITE, perimeterTypes.FOLDER, perimeterTypes.METER];

const comparisonFn = ({ type: type1, name: name1 }, { type: type2, name: name2 }) => {
    if (type1 === type2) {
        return !name1 ? 1 : !name2 ? -1 : name1.localeCompare(name2);
    }
    return TYPES_ORDER.indexOf(type1) - TYPES_ORDER.indexOf(type2);
};

/** @typedef { import('@/api/meters.service.js').Meter }  */
/**
 * @typedef {Object} MetersState
 * @property {boolean} isFetchingItems Indicates whether a fetchItems operation is in progress
 * @property {Meter[]} items The meter items
 * @property {boolean} hasFetchItemsSucceedOnce Indicates whether a fetchItems has succeed once
 */

/**
 * Get a Meter object for API requests
 * @param {Meter} meter
 * @return {{meterId: string, name: string, unit: string}}
 */
function getSimpleMeter(meter) {
    return { meterId: meter.id, unit: meter.unit };
}

/**
 * Get a icon by item
 * @param {Object} item
 * @returns {string}
 */
function getIcon(item) {
    switch (item.type) {
        case 'site':
            return { name: 'industry' };
        case 'folder':
            return { name: 'folder' };
        default:
            return { name: item.icon ?? 'gauge' };
    }
}

/** @type {MetersState} */
const _state = {
    hasFetchItemsSucceedOnce: false,
    selectedItemTimeseries: [],
    isFetchingItemTimeseries: false,
    hasErrorDataTimeseries: false,
    isFetchingItemTimeseriesCSV: false,
    hasErrorDataTimeseriesCSV: false,
    isAddingPoint: false,
    lastSelectedItemId: null,
    hasZoom: false,
    queryParamsBeforeZoom: null,
};

export const mutations = {
    /**
     * @param {MetersState} state
     */
    [FETCHING_ITEM_TIMESERIES_CSV](state) {
        state.isFetchingItemTimeseriesCSV = true;
    },
    /**
     * @param {MetersState} state
     * @param {Array} data Timeseries data
     */
    [FETCH_ITEM_TIMESERIES_CSV_SUCCESS](state) {
        state.hasErrorDataTimeseriesCSV = false;
        state.isFetchingItemTimeseriesCSV = false;
    },
    /**
     * @param {MetersState} state
     */
    [FETCH_ITEM_TIMESERIES_CSV_ERROR](state) {
        state.hasErrorDataTimeseriesCSV = true;
        state.isFetchingItemTimeseriesCSV = false;
    },
    /**
     * @param {MetersState} state
     * @param {Object} item
     */
    [UPDATE_ITEM_SUCCESS](state, item) {
        if (item.type === perimeterTypes.METER && state.perimeter?.item) {
            state.perimeter.item = item;
        }

        state.perimeter.items = Object.freeze(
            state.perimeter.items.map((_item) => (_item.id === item.id ? item : _item)),
        );
    },
    /**
     * @param {MetersState} state
     */
    [ZOOM_APPLIED](state, { queryParamsBeforeZoom }) {
        state.hasZoom = true;

        if (!state.queryParamsBeforeZoom) {
            state.queryParamsBeforeZoom = queryParamsBeforeZoom;
        }
    },
    /**
     * @param {MetersState} state
     */
    [ZOOM_RESET](state) {
        state.hasZoom = false;
        state.queryParamsBeforeZoom = null;
    },

    [SET_LAST_SELECTED_ITEM_ID](state, id) {
        state.lastSelectedItemId = id;
    },
    /**
     * @param {MetersState} state
     */
    [ADDING_POINT](state) {
        state.isAddingPoint = true;
    },
    /**
     * @param {MetersState} state
     */
    [ADD_POINT_SUCCESS](state) {
        state.isAddingPoint = false;
    },
    /**
     * @param {MetersState} state
     */
    [ADD_POINT_ERROR](state) {
        state.isAddingPoint = false;
    },
};

export const getters = {
    ...dateRangeGetters,
    isCreatingItem: (state) => state.perimeter.pending.create,
    itemsById: (state) => {
        const perimeterElements = state.perimeter?.items;
        if (perimeterElements) {
            return _.keyBy(perimeterElements, 'id');
        }
        return {};
    },
    /**
     * Return the meters.
     * @param {MetersState} state
     * @returns {array}
     */
    meters: (state) => {
        const perimeterElements = state.perimeter?.items;
        if (perimeterElements) {
            return perimeterElements
                .filter(({ type }) => type === perimeterTypes.METER)
                .map((element) => ({
                    ...element,
                    icon: getIcon(element),
                }));
        }
        return null;
    },
    /**
     * Return the sites.
     * @param {MetersState} state
     * @returns {array}
     */
    sites: (state) => {
        const perimeterElements = state.perimeter?.items;
        if (perimeterElements) {
            return perimeterElements.filter(({ type }) => type === perimeterTypes.SITE);
        }
        return null;
    },
    /**
     * Return the meters by Site id
     * @param {MetersState} state
     * @param {MeterGetters} _getters
     * @returns {object}
     */
    metersBySiteId: (state, _getters) => {
        return _.groupBy(_getters.meters, 'siteId');
    },

    /**
     * Return the sites with a location
     * @param {MetersState} state
     * @param {MeterGetters} _getters
     * @returns {array}
     */
    localizedSites: (state, _getters) => {
        const sites = _getters.sites;
        return sites?.filter((site) => !!site.location && !!site.location[0] && !!site.location[1]);
    },
    /**
     * Return the perimeter tree.
     * @param {MetersState} state
     * @returns {object}
     */
    perimeterTree: (state) => {
        const perimeterElements = state.perimeter?.items;

        if (perimeterElements) {
            const data = perimeterElements.map((item) => {
                return {
                    ...item,
                    cssClass: item.meterType,
                    icon: getIcon(item),
                };
            });
            const tree = arrayToTree(data.sort(comparisonFn), { dataField: null });
            return {
                id: 'root',
                children: tree.map((rootItem) => ({
                    ...rootItem,
                    parentId: 'root',
                })),
            };
        }
        return {
            id: 'root',
        };
    },
    /**
     * Return the meter with given ID.
     */
    getMeterById: (state, _getters) => (meterId) => {
        const meters = _getters.meters;
        return meters ? meters.find(({ id }) => id === meterId) : undefined;
    },
    /**
     * Return the meters with given IDs.
     */
    getMetersByIds:
        (state, _getters) =>
        (meterIds = []) => {
            const meters = _getters.meters;
            return meters.filter(({ id }) => meterIds.includes(id));
        },
    getMeterUnits: (state, _getters, rootState, rootGetters) => (meterId) => {
        const meter = _getters.getMeterById(meterId);

        if (!meter) {
            return [];
        }

        const dataTypeUnits = rootGetters[`${NS_DATA_TYPES}/getUnits`](meter.dataType);
        return dataTypeUnits.map((unit) => unit.symbol);
    },
    /**
     * Return the site with the given ID.
     */
    getSiteById: (state, _getters) => (siteId) => {
        const sites = _getters.sites || [];
        return sites.find(({ id }) => id === siteId);
    },

    /**
     * Return the last selected perimeter element
     *
     * @return {Meter}
     */
    lastSelectedItem: (state, _getters) => {
        return _getters.itemsById[state.lastSelectedItemId];
    },
    /**
     * The timestep as ISO-8601 duration string
     *
     * @return {string}
     */
    timestep: (state, _getters, rootState) => _.get(rootState.route, 'query.timestep'),
    /**
     * The query parameters of the current route
     *
     * @return {Object}
     */
    routeQuery: (state, _getters, rootState) => _.get(rootState, 'route.query'),
    /**
     * Return the state of isAddingPoint
     *
     * @return {Meter}
     */
    isAddingPoint: (state) => {
        return state.isAddingPoint;
    },
};

export const actions = {
    /*
     * Navigate to an item configuration.
     *
     * @param {Object} context
     * @param {Object} payload
     */
    [GO_TO_ITEM_CONFIGURATION]({ commit }, { router, id, view }) {
        if (
            router.currentRoute.matched.every(({ name }) => name !== 'customer.meters') ||
            router.currentRoute.name === 'customer.meters' ||
            router.currentRoute.name === 'meters-new' ||
            router.currentRoute.name === 'meter'
        ) {
            // case when click on meter in meters list or create new element
            router.push({
                name: 'meter-infos',
                params: {
                    id,
                },
                query: { ...router.currentRoute.query, ...(view && { view }) },
            });
        } else {
            router.push({
                name: router.currentRoute.name,
                params: {
                    id,
                },
                query: {
                    ...router.currentRoute.query,
                    ...(view && { view }),
                },
            });
        }
        commit(SET_LAST_SELECTED_ITEM_ID, id);
    },

    /**
     * Navigate to item
     */
    [GO_TO_ITEM]({ commit }, { id, router, view }) {
        router.push({
            name: 'meter',
            params: {
                id,
            },
            query: {
                ...(view && { view }),
            },
        });
        commit(SET_LAST_SELECTED_ITEM_ID, id);
    },
    /**
     * Navigate to items list
     */
    [GO_TO_ITEMS]({ rootGetters }, { router }) {
        router.push({
            path: `/${rootGetters.customerCode}/meters/`,
            query: {
                view: router.currentRoute.query.view,
            },
        });
    },

    async [FETCH_METER_TIMESERIES_CSV]({ commit, getters: _getters, rootGetters }, { ids = [], timestep, timezone }) {
        const meters = ids.reduce((acc, id) => {
            const meter = _getters.itemsById[id];
            if (meter) {
                acc.push(meter);
            }
            return acc;
        }, []);
        if (meters.length === 0) {
            return;
        }
        const notificationId = 'csvGenerating';
        commit(FETCHING_ITEM_TIMESERIES_CSV);
        notify({
            type: 'success',
            text: t('CSV_GENERATING'),
            duration: -1,
            id: notificationId,
        });
        let hasError = false;
        try {
            const data = await getDataByDateRange({
                customerCode: rootGetters.customerCode,
                meters: meters.map(getSimpleMeter),
                lang: i18next.language,
                startDate: _getters.startDate,
                endDate: _getters.endDate,
                format: 'csv',
                timestep,
                timezone,
                csvOptions: {
                    headers: ['name', 'sourceId'],
                },
            });
            const filename = getFilenameWithDateTime(meters.map(({ name }) => name).join('_'));
            downloadFile(filename, data);
        } catch (err) {
            hasError = true;
        }

        closeNotification(notificationId);
        if (hasError) {
            notify({
                type: 'error',
                text: t('CSV_GENERATION_ERROR'),
            });
            commit(FETCH_ITEM_TIMESERIES_CSV_ERROR);
        } else {
            notify({
                type: 'success',
                text: t('CSV_GENERATION_SUCCESS'),
            });
            commit(FETCH_ITEM_TIMESERIES_CSV_SUCCESS);
        }
    },
    async [APPLY_ZOOM]({ commit, getters: _getters, rootGetters }, { router, startDate, endDate }) {
        commit(ZOOM_APPLIED, {
            queryParamsBeforeZoom: _.cloneDeep(_getters.routeQuery),
        });

        const timestep = rootGetters[`${NS_SETTINGS}/getIdealTimestep`](startDate, endDate);
        router.replace({
            name: 'meter-data',
            query: {
                ..._getters.routeQuery,
                timestep,
                startDate,
                endDate,
            },
        });
    },

    async [dateRangeActionTypes.RESET_ZOOM]({ commit, state }, { router, restoreQueryParamsBeforeZoom = false }) {
        if (restoreQueryParamsBeforeZoom) {
            router.replace({
                name: 'meter-data',
                query: state.queryParamsBeforeZoom,
            });
        }

        commit(ZOOM_RESET);
    },

    /**
     * Navigate to creation form
     */
    [GO_TO_CREATE_ITEM](context, { router, type }) {
        router.push({
            name: 'meters-new',
            query: {
                type,
            },
        });
    },

    async [UPDATE_ITEM]({ commit, rootGetters }, item) {
        try {
            const updatedItem = await updatePerimeterElement({
                customerCode: rootGetters.customerCode,
                element: item,
            });
            commit(UPDATE_ITEM_SUCCESS, {
                ...updatedItem,
                type: item.type,
                meterType: item.type === perimeterTypes.METER ? (item.virtual ? 'virtual' : 'physical') : '',
            });
        } catch (error) {
            notify({
                type: 'error',
                text: t('UPDATE_ERROR'),
            });
            throw error;
        }
    },

    async [ADD_POINT]({ commit, rootGetters }, point) {
        commit(ADDING_POINT);
        try {
            await upsertPoints({
                customerCode: rootGetters.customerCode,
                points: [point],
            });
            commit(ADD_POINT_SUCCESS);
        } catch (error) {
            commit(ADD_POINT_ERROR);
            notify({ type: 'error', text: t('ADD_POINT_ERROR') });
        }
    },

    async [MOVE_ITEM]({ commit, rootGetters, state, getters: _getters }, { moved, to }) {
        const movedItem = _getters.itemsById[moved];
        const toItem = to === 'root' ? { id: null } : _getters.itemsById[to];

        if (movedItem && toItem) {
            try {
                // Sites cannot be moved to any other element than the root
                if (movedItem.type === 'site' && to !== 'root') {
                    throw new Error();
                }

                const updatedItem = {
                    ...movedItem,
                    parentId: toItem.id,
                };
                await updatePerimeterElement({
                    customerCode: rootGetters.customerCode,
                    element: updatedItem,
                });
                commit(UPDATE_ITEM_SUCCESS, updatedItem);
                notify({
                    type: 'success',
                    text: t('ITEM_MOVE_SUCCESS'),
                });
            } catch (error) {
                commit(`perimeter/${dataMutationTypes.FETCH_ITEMS_SUCCESS}`, {
                    items: [...state.perimeter.items],
                });
                notify({
                    type: 'error',
                    text: t('METERS_MOVE_NOT_ALLOWED'),
                });
            }
        }
    },
};

export default addEdition(
    {
        namespaced: true,
        state: _state,
        actions,
        mutations,
        getters,
        modules: {
            perimeter: dataModule({
                async getItems({ rootGetters }) {
                    const perimeter = (
                        await getPerimeter({
                            customerCode: rootGetters.customerCode,
                        })
                    ).map((item) => ({
                        ...item,
                        meterType: item.type === perimeterTypes.METER ? (item.virtual ? 'virtual' : 'physical') : '',
                    }));

                    // Use Object.freeze on items to avoid Vue observers
                    return Object.freeze(perimeter);
                },
                async getItem({ rootGetters }, id) {
                    const meter = await getPerimeterElement({
                        customerCode: rootGetters.customerCode,
                        id,
                        type: perimeterTypes.METER,
                    });
                    return { ...meter, type: perimeterTypes.METER };
                },
                async createItem({ dispatch, rootGetters }, { item, router, copyFrom }) {
                    try {
                        const createdItem = await createPerimeterElement({
                            customerCode: rootGetters.customerCode,
                            element: item,
                            copyFrom,
                        });
                        createdItem.meterType =
                            createdItem.type === perimeterTypes.METER
                                ? createdItem.virtual
                                    ? 'virtual'
                                    : 'physical'
                                : '';

                        dispatch(
                            `${NAMESPACE}/${GO_TO_ITEM_CONFIGURATION}`,
                            {
                                id: createdItem.id,
                                router,
                                ...([perimeterTypes.SITE, perimeterTypes.FOLDER].includes(item.type) && {
                                    view: 'tree',
                                }),
                            },
                            { root: true },
                        );
                        return createdItem;
                    } catch (error) {
                        notify({
                            type: 'error',
                            text: t('METERS_CREATE_ERROR'),
                        });
                        throw error;
                    }
                },
                async deleteItem({ dispatch, rootGetters }, { item, router, view }) {
                    try {
                        await deletePerimeterElement({
                            customerCode: rootGetters.customerCode,
                            element: item,
                        });
                        formulaValidationCache.cache.clear();
                        notify({
                            type: 'success',
                            text: t('METERS_DELETE_SUCCESS'),
                        });
                        if (view === 'table' || !item.parentId) {
                            dispatch(`${NAMESPACE}/${GO_TO_ITEMS}`, { router }, { root: true });
                        } else {
                            dispatch(`${NAMESPACE}/${GO_TO_ITEM}`, { id: item.parentId, router }, { root: true });
                        }
                    } catch (error) {
                        notify({
                            type: 'error',
                            text: t('METERS_DELETE_ERROR'),
                        });
                        throw error;
                    }
                },
            }),
            dateRange: dataModule({
                async getItem({ rootGetters }, id) {
                    return getDateRange({
                        customerCode: rootGetters.customerCode,
                        meterId: id,
                    });
                },
            }),
            queryParams: queryParamsModule({
                namespaced: false,
                fields: ['startDate', 'endDate', 'timestep'],
            }),
        },
    },
    {
        saveFunction: actions[UPDATE_ITEM],
    },
);
