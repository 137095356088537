/*eslint no-bitwise: "off" */

export const energiencyColors = {
    black: '#111',
    blue: '#009fe3',
    green: '#00e3ad',
    grey: '#bfbfbf',
    orange: '#ff6f43',
    pink: '#ed008b',
    red: '#dc143c',
    purple: '#7030a0',
    yellow: '#f7ce02',
    white: '#fff',
};

export const defaultMeterColors = [
    '#009fe3',
    '#00e3ad',
    '#ed008b',
    '#dc143c',
    '#7030a0',
    '#f7ce02',
    '#ff6f43',
    '#db7093',
    '#08519c',
    '#8b008b',
    '#000000',
];

export function lightenDarkenColor(color, percent) {
    const num = parseInt(color.replace('#', ''), 16),
        amt = Math.round(2.55 * percent),
        R = (num >> 16) + amt,
        B = ((num >> 8) & 0x00ff) + amt,
        G = (num & 0x0000ff) + amt;
    let r = 255;
    let b = 255;
    let g = 255;
    if (R < 255) {
        r = R < 1 ? 0 : R;
    }
    if (B < 255) {
        b = B < 1 ? 0 : B;
    }
    if (G < 255) {
        g = G < 1 ? 0 : G;
    }
    return '#' + (0x1000000 + r * 0x10000 + b * 0x100 + g).toString(16).slice(1);
}
