import { isNil, isNumber } from 'lodash';
import { isFile, partitionObject } from '@/utils/download.utils';
import api from './api.config';

/**
 * @typedef {Object} Action
 * @property {string} id                        ID of the action
 * @property {string} creationDate              Creation date of the action, as ISO-8601 string
 * @property {string} title                     Title
 * @property {string} description               Description
 * @property {string} ownerId                   Owner id (objectId mongo, future : uuid of the owner)
 * @property {string} siteId                    Site id
 * @property {string} supervisorId              Supervisor id (objectId mongo, future : uuid of the supervisor)
 * @property {string} dueDate                   Due date of the action, as ISO-8601 string
 * @property {string} startDate                 Start date of the action, as ISO-8601 string
 * @property {string} endDate                   End date of the action, as ISO-8601 string
 * @property {string} state                     State of the action
 * @property {string} progress                  Progression of the action (as a percentage)
 * @property {number} cost                      Cost of the action
 * @property {string} energyType                Energy type of the action
 * @property {string} SUE                       Significant Energy Use
 * @property {string} checkMethod               Check method
 * @property {string} savingProgress            Saving progress
 * @property {string} department                Department
 * @property {string} priority                  Priority
 * @property {number} potentialCostSaving       Potential cost saving
 * @property {number} potentialUnitSaving       Potential unit saving
 * @property {number} potentialPercentageSaving Potential percentage saving
 * @property {number} currentCostSaving         Current cost saving
 * @property {number} currentUnitSaving         Current unit saving
 * @property {number} currentPercentageSaving   Current percentage saving
 * @property {number} theoricalROI              Theorical ROI
 * @property {number} currentROI                Current ROI
 * @property {string} financialComment          A financial comment
 */

/** @typedef { import('@/api/tasks.service.js').Task }  */

/**
 * Return the timeseries base URL.
 *
 * @param {string} customerCode Customer code
 * @return {string} URL
 */
function actionsBaseUrl(customerCode) {
    return `/timeseries/v2/${customerCode}/actions`;
}

/**
 * Retrieve all actions from the REST API.
 *
 * @param {Object}  params
 * @param {String}  params.customerCode Customer code
 * @param {String}  params.sort
 * @param {Object}  params.filters
 * @return {Promise<{data: Action[], total: Number}>} actions
 */
export async function getActions({ customerCode, sort, filters, page, perPage }) {
    try {
        const response = await api.get(`${actionsBaseUrl(customerCode)}`, {
            headers: {
                Accept: 'application/json',
            },
            params: {
                ...(sort && { _sort: sort }),
                ...(isNumber(page) && { _page: page }),
                ...(isNumber(perPage) && { _perPage: perPage }),
                ...filters,
            },
        });
        return { data: response.data, total: parseInt(response.headers['x-items-count']) };
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * Create an action
 *
 * @param {Object} params
 * @param {string} params.customerCode Customer code
 * @param {Action} params.action An action create
 * @return {Promise<Action>} The created action with an id
 */

export async function createAction({ customerCode, action, copyFrom }) {
    try {
        const response = await api.post(`${actionsBaseUrl(customerCode)}`, action, {
            headers: {
                Accept: 'application/json',
            },
            ...(action && copyFrom ? { params: { copyFrom } } : {}),
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * Get an action
 *
 * @param {Object} params
 * @param {string} params.customerCode Customer code
 * @param {string} params.id The action ID
 * @return {Promise<Action>} The action
 */

export async function getAction({ customerCode, id }) {
    if (isNil(id)) {
        return;
    }
    try {
        const response = await api.get(`${actionsBaseUrl(customerCode)}/${id}`, {
            headers: {
                Accept: 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * Get Tasks associated to an action
 *
 * @param {Object} params
 * @param {string} params.customerCode Customer code
 * @param {string} params.id The action ID
 * @return {Promise<Task[]>} The tasks
 */

export async function getTasksForAction({ customerCode, id }) {
    try {
        const response = await api.get(`${actionsBaseUrl(customerCode)}/${id}/tasks`, {
            headers: {
                Accept: 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * Get statistics associated to an evaluation
 *
 * @param {Object} params
 * @param {string} params.customerCode Customer code
 * @param {string} params.evaluation The evaluation
 * @param {string} params.timezone The timezone
 * @return {Promise<EvaluationStatistics[]>} The statistics
 */
export async function getEvaluationStatistics({ customerCode, evaluation, timezone }) {
    try {
        const response = await api.post(
            `${actionsBaseUrl(customerCode)}/statistics`,
            {
                ...evaluation,
                timezone,
            },
            {
                headers: {
                    Accept: 'application/json',
                },
            },
        );
        return response.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * Get aggregations of a given evaluation
 *
 * @param {Object} params
 * @param {string} params.customerCode Customer code
 * @param {string} params.evaluation The evaluation
 * @param {string} params.timezone The timezone
 * @param {string} params.currency currency to use for column headers
 * @param {string} params.format   Format ("json" or "csv")
 * @param {string} params.lang     Language to use for column headers
 * @return {Promise<EvaluationStatistics[]>} The statistics
 */
export async function getEvaluationAggregations({
    customerCode,
    evaluation,
    timezone,
    currency,
    format = 'json',
    lang = 'en',
}) {
    try {
        const response = await api.post(
            `${actionsBaseUrl(customerCode)}/aggregations`,
            {
                ...evaluation,
                timezone,
                currency,
            },
            {
                headers: {
                    Accept: format === 'csv' ? 'text/csv' : 'application/json',
                    'Accept-Language': lang,
                },
            },
        );
        return response.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * Update an action
 *
 * @param {Object} params
 * @param {string} params.customerCode Customer code
 * @param {Action} params.action An action create
 * @return {Promise<Action>} The action
 */
export async function updateAction({ customerCode, action }) {
    let payload = action;
    const [files, values] = partitionObject(action, isFile);
    const fileEntries = Object.entries(files);
    if (fileEntries.length > 0) {
        const formData = new FormData();
        for (const [key, file] of fileEntries) {
            formData.append(key, file);
        }
        const jsonPayload = new Blob([JSON.stringify(values)], { type: 'application/json' });
        formData.append('jsonPayload', jsonPayload, 'jsonPayload');
        payload = formData;
    }
    try {
        const response = await api.put(`${actionsBaseUrl(customerCode)}/${action.id}`, payload, {
            headers: {
                Accept: 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(error?.response?.data);
    }
}

/**
 * Delete an action from the REST API.
 *
 * @param {Object} params
 * @param {string} params.customerCode Customer code
 * @param {string} params.id The action ID
 */
export async function deleteAction({ customerCode, id }) {
    try {
        await api.delete(`${actionsBaseUrl(customerCode)}/${id}`, {
            headers: {
                Accept: 'application/json',
            },
        });
    } catch (error) {
        throw new Error(error.response);
    }
}
