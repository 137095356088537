<template>
    <notifications position="top center" animation-name="v-fade-left">
        <template #body="props">
            <div class="message pa3" :class="props.item.type" @click="props.close">
                <UiIcon :icon-name="getIcon(props.item.type)" size="small" class="mr2 notification-icon" />
                <div v-html="props.item.text" />
            </div>
        </template>
    </notifications>
</template>
<script>
import { UiIcon } from '@energiency/ui';

export default {
    components: {
        UiIcon,
    },
    methods: {
        getIcon(type) {
            switch (type) {
                case 'success':
                    return 'circle-check';
                case 'error':
                    return 'circle-xmark';
                case 'warning':
                    return 'circle-exclamation';
                case 'info':
                    return 'circle-info';
            }
        },
    },
};
</script>

<!-- eslint-disable vue/enforce-style-attribute -->
<style lang="less">
@import (reference) '~@energiency/ui/src/style/colors.less';

.vue-notification-group {
    top: var(--spacing-medium) !important;
}

.vue-notification-wrapper {
    margin-top: var(--spacing-small);
    border-radius: 4px;
    box-shadow: 0px 4px 8px ~'rgba(var(--black-color-rgb), 0.2)';
    background-color: white;
}

.message {
    display: flex;
    align-items: center;
    cursor: pointer;

    .iter(length(@outline-colors));
    .iter(@i) when (@i > 0) {
        .iter(@i - 1);
        @line: extract(@outline-colors, @i);
        @name: extract(@line, 1);
        @background-color: extract(@line, 2);
        @text-color: extract(@line, 4);
        @icon-color: extract(@line, 5);

        &.@{name} {
            background-color: @background-color;
            color: @text-color;
            .UiIcon {
                color: @icon-color;
            }
        }
    }
}

.v-fade-left-enter-active,
.v-fade-left-leave-active,
.v-fade-left-move {
    transition: all 0.5s;
}

.v-fade-left-enter,
.v-fade-left-leave-to {
    opacity: 0;
    transform: translateY(-500px);
}

.notification-icon {
    font-size: 1.7em;
    padding-right: 4px;
}
</style>
