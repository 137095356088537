import { GO_TO_ACTION, GO_TO_ACTIONS, GO_TO_CREATE_ITEM } from './action-types';

/** @typedef { import('@/api/actions.service.js').Action }  */

export const actions = {
    /**
     * Navigate to actions list.
     *
     * @param {Object} context
     * @param {Object} payload
     */
    [GO_TO_ACTIONS]({ rootGetters }, { router }) {
        router.push({
            path: `/${rootGetters.customerCode}/actions`,
        });
    },

    /**
     * Navigate to an action.
     *
     * @param {Object} context
     * @param {Object} payload
     * @param {string} payload.actionId
     */
    [GO_TO_ACTION](context, { id, router }) {
        const query = {
            ...router.currentRoute.query,
        };

        if (
            router.currentRoute.matched.every(({ name }) => name !== 'actions') ||
            router.currentRoute.name === 'actions' ||
            router.currentRoute.name === 'actions-new'
        ) {
            // case when click on action in actions list or create new element
            router.push({
                name: 'action-infos',
                params: {
                    id,
                },
                query,
            });
        } else {
            router.push({
                name: router.currentRoute.name,
                params: {
                    id,
                },
                query,
            });
        }
    },

    /**
     * Navigate to creation form
     */
    [GO_TO_CREATE_ITEM](context, { router, type }) {
        const query = { type };

        router.push({
            name: 'actions-new',
            query,
        });
    },
};

export const NAMESPACE = 'actions';

export default {
    namespaced: true,
    actions,
};
