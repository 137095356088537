import { GO_TO_MY_TASKS, GO_TO_TASK } from './action-types';

export const NAMESPACE = 'tasks';

export default {
    namespaced: true,
    actions: {
        /**
         * Navigate to my task list view
         *
         * @param {Object} params
         * @param {Object} payload
         */
        [GO_TO_MY_TASKS](params, { router }) {
            router.push({
                name: 'my-tasks',
            });
        },

        /**
         * Navigate to task view
         *
         * @param {Object} params
         * @param {Object} payload
         * @param {string} payload.taskId
         */
        [GO_TO_TASK](params, { id, router }) {
            router.push({
                name: 'my-tasks-info',
                params: {
                    id,
                },
                query: {
                    ...router.currentRoute.query,
                },
            });
        },
    },
};
