import { isNil, remove } from 'lodash';
import { confirmDeletion } from '@/store/utils/edition.utils';

export const mutationTypes = {
    FETCH_ITEMS_PENDING: 'FETCH_ITEMS_PENDING',
    FETCH_ITEMS_SUCCESS: 'FETCH_ITEMS_SUCCESS',
    FETCH_ITEMS_ERROR: 'FETCH_ITEMS_ERROR',
    FETCH_ITEM_PENDING: 'FETCH_ITEM_PENDING',
    FETCH_ITEM_SUCCESS: 'FETCH_ITEM_SUCCESS',
    FETCH_ITEM_ERROR: 'FETCH_ITEM_ERROR',
    CREATE_ITEM_PENDING: 'CREATE_ITEM_PENDING',
    CREATE_ITEM_SUCCESS: 'CREATE_ITEM_SUCCESS',
    CREATE_ITEM_ERROR: 'CREATE_ITEM_ERROR',
    DELETE_ITEM_PENDING: 'DELETE_ITEM_PENDING',
    DELETE_ITEM_SUCCESS: 'DELETE_ITEM_SUCCESS',
    DELETE_ITEM_ERROR: 'DELETE_ITEM_ERROR',
};

export const actionTypes = {
    FETCH_ITEMS: 'FETCH_ITEMS',
    FETCH_ITEM: 'FETCH_ITEM',
    CREATE_ITEM: 'CREATE_ITEM',
    DELETE_ITEM: 'DELETE_ITEM',
};

export default function ({ getItems, getItem, createItem, deleteItem, namespaced = true }) {
    let fetchItemsPromise = null;
    return {
        namespaced,
        state() {
            return {
                items: null,
                item: null,
                pending: {
                    items: false,
                    item: false,
                    create: false,
                    delete: false,
                },
                error: {
                    items: null,
                    item: null,
                    create: null,
                    delete: null,
                },
            };
        },

        getters: {
            isItemNotFound: (state) => state.error.item?.status === 404,
        },

        mutations: {
            /**
             * @param {Object} state
             */
            [mutationTypes.FETCH_ITEMS_PENDING](state) {
                state.pending.items = true;
            },
            /**
             * @param {Object} state
             * @param {Object} payload
             * @param {Array}  payload.items List of fetched items
             */
            [mutationTypes.FETCH_ITEMS_SUCCESS](state, { items, upsert }) {
                state.pending.items = false;
                state.error.items = null;
                if (upsert) {
                    state.items = [...state.items, ...items];
                } else {
                    state.items = items;
                }
            },
            /**
             * @param {Object} state
             * @param {Object} payload
             * @param {string} payload.error Error message
             */
            [mutationTypes.FETCH_ITEMS_ERROR](state, { error }) {
                state.pending.items = false;
                state.error.items = error;
            },
            /**
             * @param {Object} state
             */
            [mutationTypes.FETCH_ITEM_PENDING](state) {
                state.pending.item = true;
            },
            /**
             * @param {Object} state
             * @param {Object} payload
             * @param {Array}  payload.item List of fetched item
             */
            [mutationTypes.FETCH_ITEM_SUCCESS](state, { item }) {
                state.pending.item = false;
                state.error.item = null;
                state.item = item;
            },
            /**
             * @param {Object} state
             * @param {Object} payload
             * @param {string} payload.error Error message
             */
            [mutationTypes.FETCH_ITEM_ERROR](state, { error }) {
                state.pending.item = false;
                state.error.item = error;
            },
            /**
             * @param {Object} state
             */
            [mutationTypes.CREATE_ITEM_PENDING](state) {
                state.pending.create = true;
            },
            /**
             * @param {Object} state
             * @param {Object} item
             */
            [mutationTypes.CREATE_ITEM_SUCCESS](state, item) {
                state.pending.create = false;
                if (isNil(state.items)) {
                    state.items = [item];
                } else if (Object.isFrozen(state.items)) {
                    state.items = Object.freeze([...state.items, item]);
                } else {
                    state.items.push(item);
                }
                state.error.create = null;
            },
            /**
             * @param {Object} state
             * @param {Object} error
             */
            [mutationTypes.CREATE_ITEM_ERROR](state, { error }) {
                state.pending.create = false;
                state.error.create = error;
            },
            /**
             * @param {Object} state
             */
            [mutationTypes.DELETE_ITEM_PENDING](state) {
                state.pending.delete = true;
            },
            /**
             * @param {Object} state
             * @param {number} id
             */
            [mutationTypes.DELETE_ITEM_SUCCESS](state, { id }) {
                state.pending.delete = false;
                const array = [...state.items];
                remove(array, (item) => id === item.id);
                state.items = array;
            },
            /**
             * @param {Object} state
             * @param {Object} error
             */
            [mutationTypes.DELETE_ITEM_ERROR](state, error) {
                state.pending.delete = false;
                state.error.delete = error;
            },
        },

        actions: {
            /**
             * Fetch items.
             *
             * @param {Object} context
             */
            async [actionTypes.FETCH_ITEMS](context, { fields, filters, sort, upsert } = {}) {
                if (fetchItemsPromise) {
                    await fetchItemsPromise;
                } else {
                    context.commit(mutationTypes.FETCH_ITEMS_PENDING);
                    try {
                        fetchItemsPromise = getItems(context, { fields, filters, sort });
                        const items = await fetchItemsPromise;
                        context.commit(mutationTypes.FETCH_ITEMS_SUCCESS, {
                            items,
                            upsert,
                        });
                    } catch (error) {
                        context.commit(mutationTypes.FETCH_ITEMS_ERROR, {
                            error,
                        });
                    } finally {
                        fetchItemsPromise = null;
                    }
                }
            },
            /**
             * Fetch one item.
             *
             * @param {Object} context
             */
            async [actionTypes.FETCH_ITEM](context, { id, ...params } = {}) {
                context.commit(mutationTypes.FETCH_ITEM_PENDING);
                try {
                    const item = await getItem(context, id, params);
                    context.commit(mutationTypes.FETCH_ITEM_SUCCESS, {
                        item,
                    });
                } catch (error) {
                    context.commit(mutationTypes.FETCH_ITEM_ERROR, {
                        error,
                    });
                }
            },
            /**
             * Create an item.
             *
             * @param {Object} context
             */
            async [actionTypes.CREATE_ITEM](context, { copyFrom, item, router }) {
                try {
                    context.commit(mutationTypes.CREATE_ITEM_PENDING);

                    const createdItem = await createItem(context, { copyFrom, item, router });
                    context.commit(mutationTypes.CREATE_ITEM_SUCCESS, createdItem);
                    return createdItem;
                } catch (error) {
                    context.commit(mutationTypes.CREATE_ITEM_ERROR, { error });
                }
            },
            /**
             * Delete an item.
             *
             * @param {Object} context
             */
            async [actionTypes.DELETE_ITEM](context, { item, router, view }) {
                try {
                    const resultConfirmDeletion = await confirmDeletion();
                    if (!resultConfirmDeletion) {
                        return;
                    }

                    context.commit(mutationTypes.DELETE_ITEM_PENDING);

                    await deleteItem(context, { item, router, view });
                    context.commit(mutationTypes.DELETE_ITEM_SUCCESS, item);
                } catch (error) {
                    context.commit(mutationTypes.DELETE_ITEM_ERROR);
                }
            },
        },
    };
}
