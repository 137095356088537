export const UPDATE_DASHBOARD_CANCEL = 'UPDATE_DASHBOARD_CANCEL';
export const UPDATE_DASHBOARD_SUCCESS = 'UPDATE_DASHBOARD_SUCCESS';
export const UPDATE_DASHBOARD_ERROR = 'UPDATE_DASHBOARD_ERROR';
export const DELETE_DASHBOARD_SUCCESS = 'DELETE_DASHBOARD_SUCCESS';
export const DELETE_DASHBOARD_ERROR = 'DELETE_DASHBOARD_ERROR';
export const EDIT_CONFIGURATION_WIDGET = 'EDIT_CONFIGURATION_WIDGET';
export const QUIT_CONFIGURATION_WIDGET = 'QUIT_CONFIGURATION_WIDGET';
export const EDIT_CONFIGURATION_DASHBOARD = 'EDIT_CONFIGURATION_DASHBOARD';
export const UPDATE_EDITED_DASHBOARD = 'UPDATE_EDITED_DASHBOARD';
export const UPDATE_DASHBOARD_PENDING = 'UPDATE_DASHBOARD_PENDING';
export const QUIT_CONFIGURATION_DASHBOARD = 'QUIT_CONFIGURATION_DASHBOARD';
export const RESET_DASHBOARD = 'RESET_DASHBOARD';

export const CREATING_FOLDER = 'CREATING_FOLDER';
export const CREATE_FOLDER_SUCCESS = 'CREATE_FOLDER_SUCCESS';
export const CREATE_FOLDER_ERROR = 'CREATE_FOLDER_ERROR';
export const UPDATE_FOLDER_SUCCESS = 'UPDATE_FOLDER_SUCCESS';

export const SET_AUTO_REFRESH = 'SET_AUTO_REFRESH';
export const SET_LAST_EXPANDED_ELEMENT_ID = 'SET_LAST_EXPANDED_ELEMENT_ID';
