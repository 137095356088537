import { t } from 'i18next';
import _ from 'lodash';
import { arrayToTree } from 'performant-array-to-tree';
import { v4 as uuidv4 } from 'uuid';
import {
    createDashboard,
    deleteWidget,
    getDashboard,
    getDashboards,
    updateDashboard,
    updateWidget,
} from '@/api/dashboards.service';
import { createFolder, deleteFolder, getFolders, updateFolder } from '@/api/folders.service';
import { getShareLinkParams } from '@/utils/sharing.utils';
import { replaceVariable } from '@/utils/variable.utils';
import { notify } from '@/helpers/notifications';
import { dateRangeActionTypes, dateRangeGetters } from '@/store/getters.utils';
import dataModule, { actionTypes as dataActionTypes } from '@/store/reusable-modules/data-list.module';
import gridModule, {
    actionTypes as gridActionTypes,
    mutationTypes as gridMutationTypes,
} from '@/store/reusable-modules/grid.module';
import queryParamsModule from '@/store/reusable-modules/query-params.module';
import { addEdition, confirmDeletion } from '@/store/utils/edition.utils';
import {
    CREATE_FOLDER,
    CREATE_WIDGET,
    DELETE_WIDGET,
    GO_TO_CONFIGURE_WIDGET,
    GO_TO_CREATE_DASHBOARD,
    GO_TO_CREATE_WIDGET,
    GO_TO_DASHBOARDS,
    GO_TO_ITEM,
    GO_TO_WIDGET_COMMENTS,
    UPDATE_DASHBOARD,
    UPDATE_FOLDER,
    UPDATE_WIDGET,
} from './action-types';
import {
    CREATE_FOLDER_ERROR,
    CREATE_FOLDER_SUCCESS,
    CREATING_FOLDER,
    EDIT_CONFIGURATION_DASHBOARD,
    EDIT_CONFIGURATION_WIDGET,
    QUIT_CONFIGURATION_DASHBOARD,
    QUIT_CONFIGURATION_WIDGET,
    RESET_DASHBOARD,
    SET_AUTO_REFRESH,
    SET_LAST_EXPANDED_ELEMENT_ID,
    UPDATE_DASHBOARD_CANCEL,
    UPDATE_DASHBOARD_ERROR,
    UPDATE_DASHBOARD_PENDING,
    UPDATE_DASHBOARD_SUCCESS,
    UPDATE_EDITED_DASHBOARD,
    UPDATE_FOLDER_SUCCESS,
} from './mutation-types';

export const NAMESPACE = 'dashboards';

const LOCAL_STORAGE_AUTO_REFRESH_KEY = `${NAMESPACE}AutoRefresh`;

/** @type {DashboardState} */
const _state = {
    autoRefresh: JSON.parse(localStorage.getItem(LOCAL_STORAGE_AUTO_REFRESH_KEY)) || false,
    editedWidget: null,
    editedDashboard: null,
    isUpdatingDashboard: false,
    isMovingDashboard: false,
    lastUptadedDate: false,
    isCreatingFolder: false,
    lastExpandedElementId: null,
};

export const getters = {
    ...dateRangeGetters,
    isCreatingDashboard: (state) => state.dashboards.pending.create,
    /**
     * Return the ID corresponding to the route `:id`.
     *
     * @param {DashboardState} state
     * @param {DashboardGetters} _getters
     * @param {RootState} rootState
     * @return {string} Dashboard ID
     */
    selectedItemId: (state, _getters, rootState) => {
        return getShareLinkParams(rootState.route)?.dashboardId || _.get(rootState.route, 'params.id');
    },
    selectedItem: (state) => {
        return _.get(state, 'dashboards.item');
    },
    /**
     * Return the ID of the widget corresponding to the route param `:widgetId`.
     *
     * @param {DashboardState} state
     * @param {DashboardGetters} _getters
     * @param {RootState} rootState
     * @return {string} ID of the selected widget
     */
    selectedWidgetId: (state, _getters, rootState) => {
        return _.get(rootState.route, 'params.widgetId');
    },
    /**
     * Return the widget corresponding to the route param `:widgetId`.
     *
     * @param {DashboardState} state
     * @param {DashboardGetters} _getters
     * @return {object} Widget object
     */
    selectedWidget: (state, _getters) => {
        const widgetId = _getters.selectedWidgetId;
        return _getters.getWidgetById(widgetId);
    },
    dashboards: (state) => {
        return _.get(state, 'dashboards.items');
    },
    folders: (state) => {
        return _.get(state, 'folders.items') || [];
    },
    dashboardsAndFolders: (state, _getters) => {
        return [
            ...(_getters.dashboards || []).map((dashboard) => ({
                type: 'dashboard',
                cssClass:
                    dashboard.template && dashboard.scheduling?.enabled
                        ? 'report-template'
                        : dashboard.template
                        ? 'template'
                        : dashboard.scheduling?.enabled
                        ? 'report'
                        : '',
                ...dashboard,
            })),
            ..._getters.folders.map((folder) => ({ type: 'folder', icon: { name: 'folder' }, ...folder })),
        ];
    },
    dashboardsAndFoldersById: (state, _getters) => {
        return _.keyBy(_getters.dashboardsAndFolders, 'id');
    },
    selectedFolder: (state, _getters, rootState) => {
        return _.get(rootState.route, 'query.folder');
    },
    /**
     * Return the dashboard's templates
     *
     * @param {DashboardState} state
     * @returns {array}} The template list
     */
    templates: (state) => {
        return state?.templates?.items ?? [];
    },

    /**
     * The timestep as ISO-8601 durationstring
     *
     * @param {DashboardState} state
     * @param {DashboardGetters} _getters
     * @param {RootState} rootState
     * @return {string}
     */
    timestep: (state, _getters, rootState) => _.get(rootState.route, 'query.timestep'),
    /**
     * Return the dashboards tree.
     * @param {DashboardState} state
     * @param {DashboardGetters} _getters
     * @returns {object}
     */
    dashboardsTree: (state, _getters) => {
        const tree = arrayToTree(_getters.dashboardsAndFolders, { dataField: null });
        return {
            id: 'root',
            children: tree.map((rootItem) => ({
                ...rootItem,
                parentId: 'root',
            })),
        };
    },
    lastExpandedElementId: (state, _getters) => {
        const isValidFolder = _getters.folders.find((folder) => folder.id === state.lastExpandedElementId);
        return isValidFolder ? state.lastExpandedElementId : null;
    },
    /**
     * Return the folder by id
     * @param {DashboardState} state
     * @param {DashboardGetters} _getters
     * @returns {object}
     */
    getFolderById: (state, _getters) => (folderId) => {
        return _getters.folders.find(({ id }) => id === folderId);
    },
    /**
     * Return the widget by id
     * @param {DashboardState} state
     * @param {DashboardGetters} _getters
     * @returns {object}
     */
    getWidgetById: (state, _getters) => (widgetId) => {
        const widgets = _getters.selectedItem?.widgets ?? [];
        return widgets.find(({ id }) => id === widgetId);
    },

    /**
     * Return the datasets by widgetId
     * @param {DashboardState} state
     * @param {DashboardGetters} _getters
     * @returns {object}
     */
    getDatasetsByWidgetId: (state, _getters) => (widgetId) => {
        const widget = _getters.getWidgetById(widgetId);
        let datasets = [];
        switch (widget.type) {
            case 'timeseries-table':
                datasets = widget.data.series ?? [];
                break;
            case 'timeseries':
                datasets = [...(widget.data.yAxis?.series ?? []), ...(widget.data.y2Axis?.series ?? [])];
                break;
        }
        return datasets;
    },
    /**
     * Return the dataset with a widgetId and a meterId
     * @param {DashboardState} state
     * @param {DashboardGetters} _getters
     * @returns {object}
     */
    getDatasetByMeterId: (state, _getters) => (widgetId, meterId) => {
        return _getters
            .getDatasetsByWidgetId(widgetId)
            .filter((dataset) => ['meter', 'variable'].includes(dataset.type))
            .find((dataset) => {
                const datasetMeterId = dataset.meterId || _getters.getVariableById(dataset.variableId).value;
                return datasetMeterId === meterId;
            });
    },
};

export const mutations = {
    /**
     * Save last expanded element id.
     *
     * @param {MetersState} state
     * @param {string}      expandedElementId
     */
    [SET_LAST_EXPANDED_ELEMENT_ID](state, expandedElementId) {
        state.lastExpandedElementId = expandedElementId;
    },
    /**
     * @param {DashboardState} state
     * @param {Dashboard} dashboard
     */
    [UPDATE_DASHBOARD_SUCCESS](state, { dashboardId, propertiesToUpdate }) {
        state.isUpdatingDashboard = false;
        if (state.dashboards && state.dashboards.items) {
            const index = state.dashboards.items.findIndex(({ id }) => id === dashboardId);
            if (index >= 0) {
                state.dashboards.items.splice(index, 1, { ...state.dashboards.items[index], ...propertiesToUpdate });
            }
        }
        if (state.editedDashboard) {
            state.editedDashboard = { ...state.editedDashboard, ...propertiesToUpdate };
        }
        state.dashboards.item = { ...state.dashboards.item, ...propertiesToUpdate };
    },
    /**
     * @param {DashboardState} state
     */
    [UPDATE_DASHBOARD_CANCEL](state) {
        state.isUpdatingDashboard = false;
    },
    /**
     * @param {DashboardState} state
     */
    [UPDATE_DASHBOARD_ERROR](state) {
        notify({
            type: 'error',
            text: t('UPDATE_DASHBOARD_ERROR'),
        });
        state.isUpdatingDashboard = false;
    },
    /**
     * Set a widget as being configured.
     *
     * @param {DashboardState} state
     * @param {Object} editedWidget
     */
    [EDIT_CONFIGURATION_WIDGET](state, editedWidget) {
        state.editedWidget = _.cloneDeep(editedWidget);
    },
    /**
     * Set no widget as being configured.
     *
     * @param {DashboardState} state
     * @param {Object} editedWidget
     */
    [QUIT_CONFIGURATION_WIDGET](state) {
        state.editedWidget = null;
    },
    /**
     * @param {DashboardState} state
     * @param {Object} editedDashboard
     */
    [EDIT_CONFIGURATION_DASHBOARD](state, editedDashboard) {
        state.editedDashboard = _.cloneDeep(editedDashboard);
    },
    /**
     * Update the configuration of the currently edited dashboard.
     * (But not the widgets)
     *
     * @param {DashboardState} state
     * @param {Object} editedDashboard
     */
    [UPDATE_EDITED_DASHBOARD](state, editedDashboard) {
        const { widgets, ...dashboard } = editedDashboard;
        Object.assign(state.editedDashboard, dashboard);
    },

    /**
     * @param {*} state
     */
    [UPDATE_DASHBOARD_PENDING](state) {
        state.isUpdatingDashboard = true;
    },
    /**
     * @param {*} state
     */
    [QUIT_CONFIGURATION_DASHBOARD](state) {
        state.editedDashboard = null;
    },
    /**
     * @param {DashboardState} state
     */
    [CREATING_FOLDER](state) {
        state.isCreatingFolder = true;
    },
    /**
     * @param {DashboardState} state
     * @param {*} folder
     */
    [CREATE_FOLDER_SUCCESS](state, folder) {
        state.folders.items = [...state.folders.items, folder];
        state.isCreatingFolder = false;
    },
    /**
     * @param {DashboardState} state
     */
    [CREATE_FOLDER_ERROR](state) {
        state.isCreatingFolder = false;
    },
    /**
     * @param {DashboardState} state
     * @param {*} updatedFolder
     */
    [UPDATE_FOLDER_SUCCESS](state, updatedFolder) {
        const folders = _.get(state, 'folders.items');
        if (folders) {
            const folder = folders.find(({ id }) => id === updatedFolder.id);
            Object.assign(folder, updatedFolder);
        }
    },
    /**
     * Save auto refresh value
     *
     * @param {DashboardState} state
     * @param {boolean} autoRefresh
     */
    [SET_AUTO_REFRESH](state, autoRefresh) {
        state.autoRefresh = autoRefresh;
        localStorage.setItem(LOCAL_STORAGE_AUTO_REFRESH_KEY, JSON.stringify(autoRefresh));
    },
    /**
     * @param {DashboardState} state
     */
    [RESET_DASHBOARD](state) {
        state.dashboards.item = null;
    },
};

export const actions = {
    /**
     * Navigate to dashboard creation form
     *
     * @param {Object} context
     * @param {Object} payload
     */
    [GO_TO_CREATE_DASHBOARD]({ getters: _getters }, { router, schedulingEnabled, templateEnabled, libraryMode, view }) {
        let query = {};
        if (schedulingEnabled || templateEnabled) {
            query = {
                schedulingEnabled,
                templateEnabled,
            };
        }

        const { selectedFolder } = _getters;

        if (view === 'tree' && selectedFolder) {
            query = {
                ...query,
                view,
                folder: selectedFolder,
            };
        }
        const name = libraryMode ? 'dashboards-library-new' : 'dashboards-new';

        router.push({
            name,
            query,
        });
    },
    /**
     * Navigate to dashboards list
     *
     * @param {Object} context
     * @param {Object} payload
     */
    [GO_TO_DASHBOARDS](context, { router, folder }) {
        const newQueryParams = folder
            ? {
                  view: 'tree',
                  folder,
              }
            : {};

        const { folder: oldFolderParam, ...query } = router.currentRoute.query || {};

        router.push({
            name: 'customer.dashboards',
            query: {
                ...query,
                ...newQueryParams,
            },
        });
    },
    /**
     * Navigate to a dashboard or a folder.
     *
     * @param {Object} context
     * @param {Object} payload
     */
    [GO_TO_ITEM]({ commit, getters: _getters }, { id, router }) {
        const element = _getters.dashboardsAndFoldersById[id];

        if (element && element.type === 'folder') {
            router.push({
                query: {
                    ...router.currentRoute.query,
                    view: 'tree',
                    folder: id,
                },
            });
        } else {
            router.push({
                name: 'customer.dashboard',
                params: {
                    id,
                },
                query: {
                    ...router.currentRoute.query,
                },
            });
        }

        commit(gridMutationTypes.SET_WIDGET_FULLSCREEN, null);
    },

    /**
     * Go to the configuration of a widget.
     *
     * @param {Object} context
     * @param {Object} payload
     */
    [GO_TO_CONFIGURE_WIDGET](context, { router, widgetId }) {
        router.push({
            name: 'widget',
            params: {
                widgetId,
            },
            query: router.currentRoute.query,
        });
    },

    async [UPDATE_DASHBOARD]({ commit, getters: _getters, rootGetters }, dashboard) {
        if (!dashboard.description) {
            dashboard.description = '';
        }
        try {
            commit(UPDATE_DASHBOARD_PENDING);

            // replace deleted variables by their value in dashboard
            const oldVariables = _getters.selectedItem.variables;
            const currentVariableIds = dashboard.variables.map(({ id }) => id);
            const deletedVariables = oldVariables.filter(({ id }) => !currentVariableIds.includes(id));
            for (const variable of deletedVariables) {
                dashboard = replaceVariable(variable, dashboard);
            }
            const updatedDashboard = await updateDashboard({
                customerCode: rootGetters.customerCode,
                dashboard,
                cancellable: true,
            });
            if (updatedDashboard) {
                const propsToUpdate = ['meterIds', 'meters', 'modificationDate'];
                if (deletedVariables.length > 0 || !_.isEqual(_getters.selectedItem.widgets, dashboard.widgets)) {
                    propsToUpdate.push('widgets');
                }
                commit(UPDATE_DASHBOARD_SUCCESS, {
                    dashboardId: dashboard.id,
                    propertiesToUpdate: { ...dashboard, ..._.pick(updatedDashboard, propsToUpdate) },
                });
            } else {
                commit(UPDATE_DASHBOARD_CANCEL);
            }
        } catch (error) {
            commit(UPDATE_DASHBOARD_ERROR);
            throw new Error(error.response.data);
        }
    },

    [dateRangeActionTypes.RESET_ZOOM]() {
        // prevent display error from DateTimeRangeTimestepPicker
    },

    async [UPDATE_FOLDER]({ commit, rootGetters }, folder) {
        if (!folder.description) {
            folder.description = '';
        }
        try {
            const updatedFolder = await updateFolder({
                customerCode: rootGetters.customerCode,
                folder,
            });
            commit(UPDATE_FOLDER_SUCCESS, updatedFolder);
        } catch (error) {
            notify({
                type: 'error',
                text: t('UPDATE_FOLDER_ERROR'),
            });
            throw new Error(error.response.data);
        }
    },
    [GO_TO_CREATE_WIDGET](context, { router }) {
        router.push({
            name: 'create-widget',
            query: router.currentRoute.query,
        });
    },
    async [CREATE_WIDGET]({ dispatch, rootState }, { widget, router, redirect }) {
        // clone the widget
        const newWidget = _.cloneDeep(widget);

        // set id
        newWidget.id = uuidv4();

        // clone dashboard
        const dashboard = rootState.dashboards.dashboards.item;
        const copyDashboard = _.cloneDeep(dashboard);
        // set row & col
        newWidget.layout = {
            ...newWidget.layout,
            row: Math.max(...copyDashboard.widgets.map((i) => i.layout.row + i.layout.height), 0),
            col: 0,
        };
        // add new widget to dashboard
        copyDashboard.widgets = [...copyDashboard.widgets, newWidget];
        await dispatch(`${NAMESPACE}/${UPDATE_DASHBOARD}`, copyDashboard, { root: true });

        // redirect to the new widget configuration
        if (redirect) {
            dispatch(GO_TO_CONFIGURE_WIDGET, { widgetId: newWidget.id, router });
        }
    },
    /**
     * Update a widget from the current dashboard
     * @param {Objet} context
     * @param {Object} payload
     */
    async [UPDATE_WIDGET]({ commit, getters: _getters, rootGetters }, { widget, refresh = false }) {
        try {
            commit(UPDATE_DASHBOARD_PENDING);
            const dashboard = _getters.selectedItem;
            const updatedWidget = await updateWidget({
                customerCode: rootGetters.customerCode,
                id: dashboard.id,
                widget,
                cancellable: true,
            });
            if (updatedWidget) {
                const propsToUpdate = ['modificationDate'];
                let source = updatedWidget;
                if (refresh) {
                    propsToUpdate.push('meterIds', 'meters');
                    source = await getDashboard({ customerCode: rootGetters.customerCode, id: dashboard.id });
                }
                const widgets = dashboard.widgets.map((w) => (w.id === widget.id ? updatedWidget : w));
                commit(UPDATE_DASHBOARD_SUCCESS, {
                    dashboardId: dashboard.id,
                    propertiesToUpdate: { widgets, ..._.pick(source, propsToUpdate) },
                });
            } else {
                commit(UPDATE_DASHBOARD_CANCEL);
            }
        } catch (error) {
            commit(UPDATE_DASHBOARD_ERROR);
            throw new Error(error.response.data);
        }
    },
    /**
     * Delete a widget with given id from the current dashboard.
     *
     * @param {Object} context
     * @param {string} widgetId ID of the widget to remove
     */
    async [DELETE_WIDGET]({ commit, dispatch, getters: _getters, rootGetters }, { widgetId, router }) {
        const resultConfirmDeletion = await confirmDeletion();
        if (!resultConfirmDeletion) {
            return;
        }
        try {
            commit(UPDATE_DASHBOARD_PENDING);
            const dashboard = _getters.selectedItem;
            await deleteWidget({ customerCode: rootGetters.customerCode, id: dashboard.id, widgetId });

            commit(UPDATE_DASHBOARD_SUCCESS, {
                dashboardId: dashboard.id,
                propertiesToUpdate: {
                    modificationDate: new Date(Date.now()).toISOString(), // update modification date
                    widgets: dashboard.widgets.filter((w) => w.id !== widgetId),
                },
            });

            commit(gridMutationTypes.SET_WIDGET_FULLSCREEN, null);

            if (_getters.selectedWidgetId) {
                dispatch(GO_TO_ITEM, { id: dashboard.id, router });
            }
        } catch (error) {
            commit(UPDATE_DASHBOARD_ERROR);
            throw new Error(error.response.data);
        }
    },

    async [CREATE_FOLDER]({ commit, dispatch, rootGetters }, { folder, router }) {
        commit(CREATING_FOLDER);
        try {
            const createdFolder = await createFolder({
                customerCode: rootGetters.customerCode,
                folder: {
                    ...folder,
                    context: 'dashboards',
                },
            });
            notify({
                type: 'success',
                text: t('CREATE_FOLDER_SUCCESS'),
            });
            commit(CREATE_FOLDER_SUCCESS, createdFolder);
            dispatch(GO_TO_DASHBOARDS, {
                folder: createdFolder.id,
                router,
            });
        } catch (error) {
            notify({
                type: 'error',
                text: t('CREATE_FOLDER_ERROR'),
            });
            commit(CREATE_FOLDER_ERROR);
        }
    },
    /**
     * Go to the comments of a widget
     *
     * @param {Object} context
     * @param {Object} payload
     */
    [GO_TO_WIDGET_COMMENTS](context, { router, widgetId, timestamps }) {
        router.push({
            name: 'widget-comments',
            params: {
                widgetId,
            },
            query: { ...router.currentRoute.query, timestamps },
        });
    },
};

export default addEdition(
    {
        namespaced: true,
        state: _state,
        getters,
        getPersistedStatePaths() {
            return ['lastExpandedElementId'].map((propPath) => `${NAMESPACE}.${propPath}`);
        },
        actions,
        mutations,
        modules: {
            grid: gridModule({
                namespaced: false,
                getGrid: (state, _getters, rootState) => rootState[NAMESPACE].dashboards.item,
                updateGridWidget: ({ dispatch }, { widget, refresh }) => dispatch(UPDATE_WIDGET, { widget, refresh }),
            }),
            widgets: addEdition(
                { namespaced: true },
                {
                    async saveFunction({ dispatch }, widget) {
                        await dispatch(
                            `${NAMESPACE}/${gridActionTypes.UPDATE_GRID_WIDGET}`,
                            { widget, refresh: true },
                            { root: true },
                        );
                    },
                },
            ),
            folders: addEdition(
                dataModule({
                    namespaced: true,
                    async getItems({ rootGetters }, { fields }) {
                        return await getFolders({
                            customerCode: rootGetters.customerCode,
                            fields,
                            filters: { context: 'dashboards' },
                        });
                    },
                    async deleteItem({ dispatch, rootGetters }, { item, router }) {
                        try {
                            await deleteFolder({
                                customerCode: rootGetters.customerCode,
                                id: item.id,
                            });
                            notify({
                                type: 'success',
                                text: t('DELETE_FOLDER_SUCCESS'),
                            });

                            // Refresh dashboards since some dashboards have been deleted
                            await dispatch(`${NAMESPACE}/${dataActionTypes.FETCH_ITEMS}`, undefined, { root: true });
                            dispatch(
                                `${NAMESPACE}/${GO_TO_DASHBOARDS}`,
                                { router, folder: item.parentId },
                                { root: true },
                            );
                        } catch (error) {
                            notify({
                                type: 'error',
                                text: t('DELETE_FOLDER_ERROR'),
                            });
                            throw error;
                        }
                    },
                }),
                {
                    async saveFunction({ dispatch }, folder) {
                        dispatch(`${NAMESPACE}/${UPDATE_FOLDER}`, folder, { root: true });
                    },
                },
            ),
            dashboards: dataModule({
                namespaced: false,
                async getItem({ rootGetters }, id, params) {
                    return await getDashboard({
                        customerCode: rootGetters.customerCode,
                        id,
                        shareLinkId: params.shareLinkId,
                    });
                },
                async getItems({ rootGetters }, { fields }) {
                    return await getDashboards({ customerCode: rootGetters.customerCode, fields });
                },
                async createItem({ dispatch, rootGetters }, { item, copyFrom, router }) {
                    try {
                        const createdDashboard = await createDashboard({
                            customerCode: rootGetters.customerCode,
                            dashboard: item,
                            copyFrom,
                        });
                        notify({
                            type: 'success',
                            text: t('CREATE_DASHBOARD_SUCCESS'),
                        });
                        dispatch(`${NAMESPACE}/${GO_TO_ITEM}`, { id: createdDashboard.id, router }, { root: true });
                        return createdDashboard;
                    } catch (error) {
                        notify({
                            type: 'error',
                            text: t('CREATE_DASHBOARD_ERROR'),
                        });
                        throw error;
                    }
                },
            }),
            templates: dataModule({
                namespaced: true,
                async getItem({ rootGetters }, id) {
                    return getDashboard({ customerCode: rootGetters.customerCode, id });
                },
                async getItems({ rootGetters }, { fields }) {
                    const filters = { template_nnull: true };
                    return getDashboards({ customerCode: rootGetters.customerCode, fields, filters });
                },
            }),
            queryParams: queryParamsModule({
                namespaced: false,
                fields: ['startDate', 'endDate', 'timestep'],
            }),
        },
    },
    {
        saveFunction({ dispatch }, dashboard) {
            return dispatch(`${NAMESPACE}/${UPDATE_DASHBOARD}`, dashboard, { root: true });
        },
    },
);
