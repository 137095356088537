import { t } from 'i18next';
import moment from 'moment-timezone';

/**
 * Get the absolute date range from a relative date range
 *
 * @param {[Number,Number]|Number}   offset    Offset
 * @param {string}  unit      Unit
 * @param {string}  timezone  Timezone
 * @return {Object} DateRange
 */
export function getRelativeDateRange(offset, unit, timezone) {
    const [startOffset, endOffset] = Array.isArray(offset) ? offset : [offset, offset];
    return {
        startDate: moment().tz(timezone).add(startOffset, unit).startOf(unit).toISOString(),
        endDate: moment().tz(timezone).add(endOffset, unit).endOf(unit).toISOString(),
    };
}

/**
 * Return a date range formatted as readable string.
 *
 * @param {Date}   startDate  Start date of the range
 * @param {Date}   endDate    End date of the range
 * @param {string} timezone   Timezone
 * @param {string} lang       Language
 * @param {string} weekPrefix String to prefix weeks (for instance 'W' -> 'W12'...)
 * @return {string} Range formatted as string
 */
export function formatDateRange(startDate, endDate, timezone, lang = 'en', weekPrefix = 'W') {
    startDate = timezone ? moment.tz(startDate, timezone) : moment(startDate);
    endDate = timezone ? moment.tz(endDate, timezone) : moment(endDate);

    startDate.locale(lang);
    endDate.locale(lang);

    var formattedDateRange;

    var startDay = startDate.isSame(moment(startDate).startOf('day'));
    var endDay = endDate.isSame(moment(endDate).endOf('day'));
    var oneDay = startDate.isSame(moment(startDate).startOf('day')) && endDate.isSame(moment(startDate).endOf('day'));
    var sameDay = startDate.isSame(endDate, 'day');
    var oneWeek =
        startDate.isSame(moment(startDate).startOf('week')) && endDate.isSame(moment(startDate).endOf('week'));
    var sameMonth = startDate.isSame(endDate, 'month');
    var fullMonths =
        startDate.isSame(moment(startDate).startOf('month')) && endDate.isSame(moment(endDate).endOf('month'));
    var oneMonth = sameMonth && fullMonths;
    var sameYear = startDate.isSame(endDate, 'year');
    var fullYears =
        startDate.isSame(moment(startDate).startOf('year')) && endDate.isSame(moment(endDate).endOf('year'));
    var oneYear = sameYear && fullYears;
    if (oneDay) {
        formattedDateRange = startDate.format('LL');
    } else if (sameDay) {
        formattedDateRange = startDate.format('LL') + ' ' + startDate.format('HH:mm') + ' - ' + endDate.format('HH:mm');
    } else if (oneWeek) {
        if (startDate.month() === endDate.month()) {
            formattedDateRange = weekPrefix + startDate.format('w D') + ' - ' + endDate.format('LL');
        } else {
            formattedDateRange = weekPrefix + startDate.format('w D MMMM') + ' - ' + endDate.format('LL');
        }
    } else if (oneMonth) {
        formattedDateRange = startDate.format('MMMM YYYY');
    } else if (oneYear) {
        formattedDateRange = startDate.format('YYYY');
    } else if (fullYears) {
        formattedDateRange = startDate.format('YYYY') + ' - ' + endDate.format('YYYY');
    } else if (fullMonths) {
        if (sameYear) {
            formattedDateRange = startDate.format('MMMM') + ' - ' + endDate.format('MMMM YYYY');
        } else {
            formattedDateRange = startDate.format('MMMM YYYY') + ' - ' + endDate.format('MMMM YYYY');
        }
    } else if (startDay && endDay && sameMonth) {
        formattedDateRange = startDate.format('D') + ' - ' + endDate.format('D MMMM YYYY');
    } else if (startDay && endDay && sameYear) {
        formattedDateRange = startDate.format('D MMMM') + ' - ' + endDate.format('D MMMM YYYY');
    } else if (!startDay || !endDay) {
        formattedDateRange =
            startDate.format('ll') +
            ' ' +
            startDate.format('HH:mm') +
            ' - ' +
            endDate.format('ll') +
            ' ' +
            endDate.format('HH:mm');
    } else {
        formattedDateRange = startDate.format('LL') + ' - ' + endDate.format('LL');
    }

    return formattedDateRange;
}

export function getShortcuts() {
    const now = moment();
    return [
        {
            text: t('TODAY_TEXT'),
            onClick() {
                return [now.clone().startOf('day').toDate(), now.clone().endOf('day').toDate()];
            },
        },
        {
            text: t('YESTERDAY_AND_TODAY_TEXT'),
            onClick() {
                return [now.clone().subtract('1', 'days').startOf('day').toDate(), now.clone().endOf('day').toDate()];
            },
        },
        {
            text: t('TODAY_AND_TOMORROW_TEXT'),
            onClick() {
                return [now.clone().startOf('day').toDate(), now.clone().add('1', 'day').endOf('day').toDate()];
            },
        },
        {
            text: t('LAST_SEVEN_DAYS_TEXT'),
            onClick() {
                return [now.clone().subtract('6', 'days').startOf('day').toDate(), now.clone().endOf('day').toDate()];
            },
        },
        {
            text: t('LAST_TWELVE_MONTHS_TEXT'),
            onClick() {
                return [
                    now.clone().subtract('12', 'months').startOf('month').toDate(),
                    now.clone().endOf('month').toDate(),
                ];
            },
        },

        {
            text: t('CURRENT_WEEK_TEXT'),
            onClick() {
                return [now.clone().startOf('week').toDate(), now.clone().endOf('week').toDate()];
            },
        },
        {
            text: t('CURRENT_MONTH_TEXT'),
            onClick() {
                return [now.clone().startOf('month').toDate(), now.clone().endOf('month').toDate()];
            },
        },
        {
            text: t('CURRENT_QUARTER_TEXT'),
            onClick() {
                return [now.clone().startOf('quarter').toDate(), now.clone().endOf('quarter').toDate()];
            },
        },
        {
            text: t('CURRENT_YEAR_TEXT'),
            onClick() {
                return [now.clone().startOf('year').toDate(), now.clone().endOf('year').toDate()];
            },
        },
    ];
}
