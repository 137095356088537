import { formatDuration as _formatDuration } from 'date-fns';
import de from 'date-fns/locale/de';
import enUS from 'date-fns/locale/en-US';
import es from 'date-fns/locale/es';
import fr from 'date-fns/locale/fr';
import it from 'date-fns/locale/it';
import ptBR from 'date-fns/locale/pt-BR';
import { default as i18next, t } from 'i18next';
import { defaultLanguage } from '@/utils/languages.utils';

const dateFnsLocales = { fr, en: enUS, it, 'pt-br': ptBR, es, de };

/**
 * Return the corresponding key of @energiency/locales.
 *
 * @param {string} aggregation The aggregation (avg, index, sum)
 * @returns {string} The key or `undefined` if the key is not found
 */
export function getAggregationPolicyKey(aggregation) {
    return {
        avg: 'AVERAGE_TEXT',
        sum: 'SUM_TEXT',
        median: 'MEDIAN_TEXT',
        min: 'MINIMUM_TEXT',
        max: 'MAXIMUM_TEXT',
        count: 'COUNT_TEXT',
    }[aggregation];
}

/**
 * Return the corresponding key of @energiency/locales.
 *
 * @param {string} unit The durationUnit (years, months, days, hours, minutes, seconds)
 * @returns {string} The key or `undefined` if the key is not found
 */
export function getDurationUnitKey(unit) {
    return {
        years: 'DURATION_YEARS_TEXT',
        months: 'DURATION_MONTHS_TEXT',
        days: 'DURATION_DAYS_TEXT',
        hours: 'DURATION_HOURS_TEXT',
        minutes: 'DURATION_MINUTES_TEXT',
        seconds: 'DURATION_SECONDS_TEXT',
    }[unit];
}

/**
 * Return the corresponding key of @energiency/locales.
 *
 * @param {string} quantityType The quantityType (numeric, text)
 * @returns {string} The key or `undefined` if the key is not found
 */
export function getQuantityTypeKey(quantityType) {
    return {
        numeric: 'QUANTITY_TYPE_NUMERIC',
        text: 'QUANTITY_TYPE_TEXT',
        index: 'QUANTITY_TYPE_INDEX',
    }[quantityType];
}

/**
 * Return the corresponding key of @energiency/locales.
 *
 * @param {boolean} virtual
 * @return {string} The translation
 */
export function getMeterTypeKey(virtual) {
    return virtual ? 'VIRTUAL_TEXT' : 'PHYSICAL_TEXT';
}

/**
 * Return the native language label
 *
 * @param {string} locale language tag
 * @return {string} The label
 */
export function getLanguageLabel(locale) {
    const key = `${locale.toUpperCase().replace('-', '_')}_LANGUAGE`;
    return t(key, { lng: locale });
}

/**
 * Return the corresponding key of @energiency/locales.
 * @param {string} role The role (reader, user, admin, superAdmin)
 * @returns {string} The key or `undefined` if the key is not found
 */
export function getRoleKey(role) {
    return {
        reader: 'ROLE_READER',
        user: 'ROLE_USER',
        admin: 'ROLE_ADMINISTRATOR',
        superAdmin: 'ROLE_SUPER_ADMINISTRATOR',
    }[role];
}

/**
 * Return the duration between the given dates in words.
 *
 * @param {string} duration ISO-8601 duration
 * @param {string} locale   Locale to translate the formatted duration
 * @return {string} Formatted duration
 */
export function formatDuration(duration, locale = i18next.language) {
    return _formatDuration(duration, { locale: dateFnsLocales[locale] });
}
/**
 * Return file size formatted with unit from locale
 *
 * @param {string} size
 * @param {string} unit
 * @returns {string} Formatted file size
 */
export function formatFileSize(size, unit = 'megabyte') {
    const megabyteFormatter = new Intl.NumberFormat(i18next.language, { style: 'unit', unit });
    return megabyteFormatter.format(size);
}

/**
 * @returns User current locale among (de, en, es, fr, it, pt) default en
 */
export function getLocale() {
    return (i18next.language ?? defaultLanguage).split('-')[0];
}

/**
 * Return item with user current locale properties
 * @param {Object} item
 * @returns
 */
export function localize(item) {
    if (!item?.locales) {
        return item;
    }
    const { locales, ...other } = item;
    return {
        ...other,
        ...locales[getLocale()],
    };
}
