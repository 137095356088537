import { default as i18next, t } from 'i18next';
import _ from 'lodash';
import { getAlertsExport } from '@/api/alerts.service';
import { downloadFile, getFilenameWithDateTime } from '@/utils/download.utils';
import { close as closeNotification, notify } from '@/helpers/notifications';
import { dateRangeGetters } from '@/store/getters.utils';
import queryParamsModule from '@/store/reusable-modules/query-params.module';
import {
    CLOSE_ALERT,
    FETCH_ALERTS_EXPORT,
    GO_TO_ALERTS,
    GO_TO_ALERTS_HISTORY,
    GO_TO_ALERT_CONFIGURATIONS,
    GO_TO_CREATE_ITEM,
    GO_TO_ITEM,
    OPEN_ALERT,
} from './action-types';
import { FETCHING_ALERTS_EXPORT, FETCH_ALERTS_EXPORT_ERROR, FETCH_ALERTS_EXPORT_SUCCESS } from './mutation-types';

/** @typedef { import('@/api/alerts.service.js').AlertStatus }  */
/** @typedef { import('@/api/alerts.service.js').AlertConfiguration }  */

/** @type {AlertsState} */
const _state = {
    hasFetchAlertCSVError: false,
    isFetchingAlertsExport: false,
};

export const mutations = {
    /**
     * @param {AlertsState} state
     */
    [FETCHING_ALERTS_EXPORT](state) {
        state.isFetchingAlertsExport = true;
    },
    /**
     * @param {AlertsState} state
     * @param {Array} data Alerts data
     */
    [FETCH_ALERTS_EXPORT_SUCCESS](state) {
        state.hasFetchAlertCSVError = false;
        state.isFetchingAlertsExport = false;
    },
    /**
     * @param {AlertsState} state
     */
    [FETCH_ALERTS_EXPORT_ERROR](state) {
        state.hasFetchAlertCSVError = true;
        state.isFetchingAlertsExport = false;
    },
};

export const getters = {
    ...dateRangeGetters,
};

export const actions = {
    /**
     * Create alert configuration
     */
    [GO_TO_CREATE_ITEM](context, { router, type }) {
        const query = { type };

        router.push({
            name: 'alert-configuration-new',
            query,
        });
    },

    async [FETCH_ALERTS_EXPORT]({ commit, getters: _getters, rootGetters }) {
        commit(FETCHING_ALERTS_EXPORT);

        const notificationId = 'csvGenerating';
        notify({
            type: 'success',
            text: t('CSV_GENERATING'),
            id: notificationId,
            duration: -1,
        });

        let hasError = false;
        try {
            const data = await getAlertsExport({
                customerCode: rootGetters.customerCode,
                lang: i18next.language,
                includeInactive: true,
                startDate: _getters.startDate,
                endDate: _getters.endDate,
            });
            const filename = getFilenameWithDateTime(t('ALERTS_TITLE'));
            downloadFile(filename, data);
        } catch (err) {
            hasError = true;
        }
        closeNotification(notificationId);
        if (hasError) {
            notify({
                type: 'error',
                text: t('CSV_GENERATION_ERROR'),
            });
            commit(FETCH_ALERTS_EXPORT_ERROR);
        } else {
            notify({
                type: 'success',
                text: t('CSV_GENERATION_SUCCESS'),
            });
            commit(FETCH_ALERTS_EXPORT_SUCCESS);
        }
    },

    /**
     * Navigate to alerts list.
     *
     * @param {Object} context
     * @param {Object} payload
     */
    [GO_TO_ALERTS](context, { router }) {
        router.push({
            name: 'alerts-active',
        });
    },

    /**
     * Navigate to alerts history list.
     *
     * @param {Object} context
     * @param {Object} payload
     * @param {Date} payload.startDate
     * @param {Date} payload.endDate
     */
    [GO_TO_ALERTS_HISTORY](context, { router, startDate, endDate }) {
        router.push({
            name: 'alerts-history',
            query: {
                startDate: startDate.toISOString(),
                endDate: endDate.toISOString(),
            },
        });
    },

    /**
     * Open an alert.
     *
     * @param {Object} params
     * @param {Date} payload.alertStartDate
     * @param {number} payload.alertConfigurationId
     * @param {Router} payload.router
     */
    [OPEN_ALERT](params, { alertStartDate, id, router }) {
        router.push({
            name: router.currentRoute.name,
            query: {
                ...router.currentRoute.query,
                date: alertStartDate,
                id,
            },
        });
    },

    /**
     * Close navigation af an alert
     *
     * @param {Object} params
     * @param {Object} payload
     */
    [CLOSE_ALERT](params, { router }) {
        router.push({
            name: router.currentRoute.name,
            query: _.pickBy(router.currentRoute.query, (value, key) => !/^date|id|fullscreen$/.test(key)),
        });
    },

    /**
     * Navigate to alerts configurations list.
     *
     * @param {Object} context
     * @param {Object} payload
     */
    [GO_TO_ALERT_CONFIGURATIONS](context, { router }) {
        router.push({
            name: 'alerts-configurations',
        });
    },

    /**
     * Navigate to an alert configuration.
     *
     * @param {Object} context
     * @param {Object} payload
     */
    [GO_TO_ITEM](context, { router, id }) {
        router.push({
            name: 'alerts-configurations',
            query: {
                id,
            },
        });
    },
};

export const NAMESPACE = 'alerts';

function getPersistedStatePaths() {
    return ['lastQueryParams'].map((propPath) => `${NAMESPACE}.${propPath}`);
}

export default {
    namespaced: true,
    state: _state,
    actions,
    mutations,
    getters,
    getPersistedStatePaths,
    modules: {
        queryParams: queryParamsModule({
            namespaced: false,
            fields: ['startDate', 'endDate'],
        }),
    },
};
