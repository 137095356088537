import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query';
import { t } from 'i18next';
import { useRouter } from 'vue-router/composables';
import {
    createAlertConfiguration,
    deleteAlertConfiguration,
    getAlertConfigurations,
    getAlertStatus,
    updateAlertConfiguration,
} from '@/api/alerts.service';
import { notify } from '@/helpers/notifications';
import { confirmDeletion } from '@/store/utils/edition.utils';
import { useCustomerCode } from '@/composables/useCustomerCode';
import { usePostHog } from '@/composables/usePostHog';

export const getAlertStatusId = (alertConfigurationId, alertStartDate) =>
    `${alertConfigurationId}_${alertStartDate.getTime()}`;

export const useGetAlertStatus = (filters) => {
    const customerCode = useCustomerCode();
    return useQuery({
        queryKey: ['alertStatus', filters],
        queryFn: async () => {
            const alertStatus = await getAlertStatus({
                customerCode: customerCode.value,
                filters: filters.value,
            });
            return alertStatus.map((alert) => ({
                ...alert,
                id: getAlertStatusId(alert.alertConfigurationId, new Date(alert.startDate)),
            }));
        },
        refetchInterval: 180000, // 3 minutes
    });
};

export const useGetAlertConfigurations = () => {
    const customerCode = useCustomerCode();
    return useQuery({
        queryKey: ['alertConfigurations'],
        queryFn: () => getAlertConfigurations({ customerCode: customerCode.value }),
    });
};

export const useCreateAlertConfiguration = () => {
    const customerCode = useCustomerCode();
    const router = useRouter();
    const queryClient = useQueryClient();
    const { capture } = usePostHog();
    return useMutation({
        mutationFn: (alertConfiguration) => {
            return createAlertConfiguration({
                customerCode: customerCode.value,
                alertConfiguration,
            });
        },
        onSuccess: (alertConfiguration) => {
            capture('create alert', { name: alertConfiguration.name, customerCode: customerCode.value });
            queryClient.invalidateQueries({ queryKey: ['alertConfigurations'] });

            notify({
                type: 'success',
                text: t('CREATE_ALERT_CONFIGURATION_SUCCESS'),
            });

            router.push({
                name: 'alerts-configurations',
                query: {
                    id: alertConfiguration.id,
                },
            });
        },
        onError: () => {
            notify({
                type: 'error',
                text: t('CREATE_ALERT_CONFIGURATION_ERROR'),
            });
        },
    });
};

export const useUpdateAlertConfiguration = () => {
    const customerCode = useCustomerCode();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ type, ...alertConfiguration }) =>
            updateAlertConfiguration({
                customerCode: customerCode.value,
                id: alertConfiguration.id,
                alertConfiguration,
            }),
        onSuccess: (updatedAlertConfiguration) => {
            queryClient.setQueryData(['alertConfigurations'], (oldData) =>
                oldData
                    ? oldData.map((a) => (a.id === updatedAlertConfiguration.id ? updatedAlertConfiguration : a))
                    : oldData,
            );
            queryClient.invalidateQueries({ queryKey: ['alertStatus'] });
        },
    });
};

export const useDeleteAlertConfiguration = () => {
    const customerCode = useCustomerCode();
    const router = useRouter();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (alertConfiguration) => {
            if (!(await confirmDeletion())) {
                return false;
            }

            return deleteAlertConfiguration({ customerCode: customerCode.value, id: alertConfiguration.id });
        },
        onSuccess: (data, deletedAlertConfiguration) => {
            if (data === false) {
                return;
            }

            notify({
                type: 'success',
                text: t('DELETE_ALERT_CONFIGURATION_SUCCESS'),
            });

            if (deletedAlertConfiguration.parentId) {
                router.push({
                    name: 'alerts-configurations',
                    query: {
                        id: deletedAlertConfiguration.parentId,
                    },
                });
            } else {
                router.push({
                    name: 'alerts-configurations',
                });
            }

            queryClient.setQueryData(['alertConfigurations'], (oldData) =>
                oldData ? oldData.filter((a) => a.id !== deletedAlertConfiguration.id) : oldData,
            );
            queryClient.invalidateQueries({ queryKey: ['alertStatus'] });
        },
        onError: () => {
            notify({
                type: 'error',
                text: t('DELETE_ALERT_CONFIGURATION_ERROR'),
            });
        },
    });
};
