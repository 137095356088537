// Actions
import { camelCase, upperFirst } from 'lodash';
import { getAction } from '@/api/actions.service';
import { getStore } from '@/store';
import { NAMESPACE as NS_SETTINGS } from '@/store/modules/settings';
import { hasRessourceAccess } from '../navigation-guards.utils';

const ActionsPage = () => import(/* webpackChunkName: "actions" */ '@/pages/Actions.vue');
const ActionsCreationForm = () =>
    import(/* webpackChunkName: "actions" */ '@/components/actions/creation-form/ActionsCreationForm.vue');
const ActionEvaluationEditionForm = () =>
    import(/* webpackChunkName: "actions" */ '@/components/actions/evaluation/ActionEvaluationEditionForm.vue');

const Actions = () => import(/* webpackChunkName: "actions" */ '@/components/actions/Actions.vue');

// Panel
const ActionsPanel = () => import(/* webpackChunkName: "actions" */ '@/components/actions/panel/ActionsPanel.vue');
const ActionsPanelInfos = () =>
    import(/* webpackChunkName: "actions" */ '@/components/actions/panel/ActionsPanelInfos.vue');
const ActionEvaluation = () =>
    import(/* webpackChunkName: "actions" */ '@/components/actions/panel/ActionEvaluation.vue');
const ActionTasks = () => import(/* webpackChunkName: "actions" */ '@/components/actions/panel/ActionTasks.vue');
const Comments = () => import(/* webpackChunkName: "comments" */ '@/components/comments/Comments.vue');
const DocumentsUploader = () =>
    import(/* webpackChunkName: "documents" */ '@/components/documents/DocumentsUploader.vue');

// Tasks
const MyTasks = () => import(/* webpackChunkName: "actions" */ '@/components/actions/my-tasks/MyTasks.vue');
const TaskPanel = () => import(/* webpackChunkName: "actions" */ '@/components/actions/my-tasks/TaskPanel.vue');

// Library
const ActionsLibrary = () =>
    import(/* webpackChunkName: "actions" */ '@/components/actions/library/ActionsLibrary.vue');
const LibraryActionPanel = () =>
    import(/* webpackChunkName: "actions" */ '@/components/actions/library/LibraryActionPanel.vue');
const LibraryActionInfos = () =>
    import(/* webpackChunkName: "actions" */ '@/components/actions/library/LibraryActionInfos.vue');
const LibraryActionTasks = () =>
    import(/* webpackChunkName: "actions" */ '@/components/actions/library/LibraryActionTasks.vue');

export default {
    name: 'customer.actions',
    path: 'actions',
    component: ActionsPage,
    redirect: { name: 'actions' },
    meta: {
        title: 'ACTIONS_TITLE',
    },
    children: [
        {
            name: 'actions',
            path: '',
            components: {
                default: Actions,
                layer: ActionsPanel,
            },
            children: [
                {
                    name: 'action-infos',
                    path: ':id/infos',
                    component: ActionsPanelInfos,
                },
                {
                    name: 'action-evaluation',
                    path: ':id/evaluation',
                    component: ActionEvaluation,
                },
                {
                    name: 'action-tasks',
                    path: ':id/tasks',
                    component: ActionTasks,
                },
                {
                    name: 'action-documents',
                    path: ':id/documents',
                    component: DocumentsUploader,
                    props: (route) => ({
                        getSubject: (id, customerCode) => getAction({ customerCode, id }),
                        subjectType: 'Action',
                        subjectId: route.params.id,
                        emptySubtitle: 'ACTIONS_DOCUMENTS_EMPTY_SUBTITLE',
                    }),
                },
                {
                    name: 'action-comments',
                    path: ':id/comments',
                    component: Comments,
                    props: (route) => ({
                        getSubject: (id, customerCode) => getAction({ customerCode, id }),
                        subjectType: 'Action',
                        subjectId: route.params.id,
                        emptySubtitle: 'ACTIONS_COMMENTS_EMPTY_SUBTITLE',
                    }),
                },
            ],
        },
        {
            name: 'actions-evaluation-configuration',
            path: ':id/evaluation-configuration',
            components: {
                fullPageLayer: ActionEvaluationEditionForm,
            },
            meta: {
                title: 'ACTION_EVALUATION_CONFIGURATION_TEXT',
            },
        },
        {
            name: 'my-tasks',
            path: 'my-tasks',
            components: {
                default: MyTasks,
                layer: TaskPanel,
            },
            children: [
                {
                    name: 'my-tasks-info',
                    path: ':id',
                },
            ],
        },
        {
            name: 'actions-library',
            path: 'library',
            components: {
                default: ActionsLibrary,
                layer: LibraryActionPanel,
            },

            children: [
                {
                    name: 'library-action-infos',
                    path: ':id/infos',
                    component: LibraryActionInfos,
                },

                {
                    name: 'library-action-tasks',
                    path: ':id/tasks',
                    component: LibraryActionTasks,
                },
            ],
        },
        {
            name: 'actions-new',
            path: 'new',
            components: {
                fullPageLayer: ActionsCreationForm,
            },
            props: {
                fullPageLayer: (route) => ({
                    type: route.query.type,
                    from: route.query.from,
                }),
            },
            async beforeEnter(to, from, next) {
                const store = getStore();
                const type = upperFirst(camelCase(to.query.type));
                const hasAccess = await hasRessourceAccess('create', upperFirst(type));
                const isActionTemplatesEnabled =
                    type !== 'libraryAction' || store.getters[`${NS_SETTINGS}/features`]?.enableActionTemplates;
                if (hasAccess && isActionTemplatesEnabled) {
                    next();
                } else {
                    next({
                        name: 'customer.actions',
                        params: { customerCode: to.params.customerCode },
                    });
                }
            },
        },
    ],
};
