<template>
    <div :class="['Sidebar h-100 flex flex-column justify-between pa2', { collapsed: isCollapsed }]">
        <div class="items flex flex-column overflow-auto">
            <SidebarItem
                v-for="item in filteredItems"
                :key="item.name"
                :item="item"
                :is-active="isActive(item)"
                :is-collapsed="isCollapsed"
            />
        </div>
        <div class="footer w-100 flex" :class="{ 'justify-between': !isCollapsed, 'justify-center': isCollapsed }">
            <About v-if="!isCollapsed" :about="aboutLink" />
            <UiIconButton
                class="collapse-button"
                data-test="collapse-button"
                :icon-name="iconCollapse"
                appearance="flat"
                @click="sidebarCollapseChange"
            />
        </div>
    </div>
</template>

<script>
import { subject } from '@casl/ability';
import moment from 'moment';
import { UiIconButton } from '@energiency/ui';
import About from './About';
import SidebarItem from './SidebarItem';
import { useImportAlertIndicator } from '@/composables/queries/useImportAlertQueries';

export default {
    components: {
        SidebarItem,
        About,
        UiIconButton,
    },
    setup() {
        const importAlertIndicator = useImportAlertIndicator();

        return {
            importAlertIndicator,
        };
    },
    data() {
        return {
            currentRoute: {},
            isCollapsed: true,
        };
    },
    computed: {
        iconCollapse() {
            return this.isCollapsed ? 'chevron-right' : 'chevron-left';
        },
        filteredItems() {
            return this.menuItems.filter((item) => this.canAccess(item));
        },
        menuItems() {
            return [
                {
                    to: { name: 'customer.home' },
                    icon: 'house-chimney',
                    label: 'HOME_TITLE',
                },
                {
                    to: { name: 'customer.dashboards' },
                    icon: 'chart-simple',
                    label: 'DASHBOARDS_TITLE',
                },
                {
                    to: { name: 'customer.reports' },
                    icon: 'file',
                    label: 'REPORTS_TITLE',
                },
                {
                    to: { name: 'customer.alerts' },
                    icon: 'triangle-exclamation',
                    label: 'ALERTS_TITLE',
                },
                {
                    to: { name: 'customer.actions' },
                    icon: 'clipboard-check',
                    label: 'MANAGEMENT_TITLE',
                },
                {
                    to: { name: 'customer.meters' },
                    icon: 'gauge',
                    label: 'METERS_TITLE',
                },
                {
                    to: { name: 'customer.imports' },
                    icon: 'cloud-arrow-up',
                    label: 'IMPORT_TITLE',
                    getBadgeColor: () => this.importAlertIndicator,
                    getTooltip: () => {
                        return this.importAlertIndicator === 'error' ? 'DATA_NOT_RECEIVED' : null;
                    },
                    can: () => this.$can('read', 'Import') || this.$can('read', 'ImportAlert'),
                },
                {
                    to: { name: 'customer.users' },
                    icon: 'users',
                    label: 'USERS_TITLE',
                    can: () => this.$can('update', subject('User', { isMe: false, role: 'reader' })),
                },
                {
                    to: { name: 'customer.activity' },
                    icon: 'clock-rotate-left',
                    label: 'ACTIVITY_LOG_TITLE',
                    can: () => this.$can('read', 'Event'),
                },
                {
                    to: { name: 'customer.documents' },
                    icon: 'folder',
                    label: 'DOCUMENTS_TAB_TEXT',
                },
                {
                    to: { name: 'customer.settings' },
                    icon: 'gear',
                    label: 'SETTINGS_TITLE',
                    can: () => this.$can('update', 'Settings'),
                },
            ];
        },
    },
    watch: {
        // https://router.vuejs.org/guide/essentials/dynamic-matching.html#reacting-to-params-changes
        $route: {
            immediate: true,
            handler() {
                this.currentRoute = this.$router.currentRoute;
            },
        },
    },
    async created() {
        this.aboutLink = {
            version: '4.4',
            tooltip: process.env.VERSION,
            label: 'Energiency &copy; ' + moment().format('YYYY'),
            href: 'http://releases.energiency.fr',
        };
        this.isCollapsed = JSON.parse(localStorage.getItem('sidebarCollapsed')) ?? true;
    },
    methods: {
        isActive(item) {
            return this.currentRoute.matched.some(({ name }) => name === item.to.name) ? true : false;
        },
        sidebarCollapseChange() {
            this.isCollapsed = !this.isCollapsed;
            localStorage.setItem('sidebarCollapsed', this.isCollapsed);
        },
        canAccess(item) {
            return item.can ? item.can() : true;
        },
    },
};
</script>

<style lang="less" scoped>
@import (reference) '~style/sidebar.less';
@import (reference) '~style/size.less';

.Sidebar {
    background-color: @sidebar-bg-color;
    transition: width 0.3s;
    width: @sidebar-collapsed-width;
    --fa-fw-width: 20px;
    flex-shrink: 0;

    &.collapsed {
        width: @sidebar-collapsed-width;
    }
    @media (--breakpoint-large) {
        width: @sidebar-uncollapsed-width;
    }

    .footer {
        min-height: 38px;
        display: flex;
        .About {
            display: none;
            @media (--breakpoint-large) {
                display: flex;
            }
        }

        /deep/ .UiButton.collapse-button {
            display: none;
            color: var(--grey-color-dark);
            background-color: @sidebar-bg-collapse-button;
            transition: background 0.3s;
            @media (--breakpoint-large) {
                display: block;
            }

            &:active,
            &:focus {
                outline: none;
                box-shadow: none;
            }
            &:hover {
                background-color: @sidebar-bg-item-hover;
            }
        }
    }

    // custom css rules for pdf printing (elements hiding, size rules and colors)
    @media print {
        display: none;
    }
}
</style>
