import { getImport } from '@/api/imports.service';
import { dateRangeGetters } from '@/store/getters.utils';
import { NAMESPACE as NS_METERS } from '@/store/modules/meters';
import { actionTypes as dataActionTypes } from '@/store/reusable-modules/data-list.module';
import queryParamsModule from '@/store/reusable-modules/query-params.module';
import { FETCH_IMPORT, GO_BACK_TO_IMPORTS, GO_TO_CREATE_IMPORT, GO_TO_IMPORT, GO_TO_IMPORTS } from './action-types';
import { FETCHING_IMPORT, FETCH_IMPORT_ERROR, FETCH_IMPORT_SUCCESS, UNSELECT_IMPORT } from './mutation-types';

/** @typedef { import('@/api/imports.service.js').Import }  */
/**
 * @typedef {Object} ImportsState
 * @property {Boolean} isFetchingImport Indicates whether a fetchImport operation is in progress
 * @property {Import} selectedImport
 * @property {Object} lastQueryParams
 */

/** @type {ImportsState} */
const _state = {
    isFetchingImport: false,
    selectedImport: null,
    lastQueryParams: null,
};

export const mutations = {
    /**
     * @param {ImportsState} state
     */
    [FETCHING_IMPORT](state) {
        state.isFetchingImport = true;
    },
    /**
     * @param {ImportsState} state
     * @param {Import[]} imports
     */
    [FETCH_IMPORT_SUCCESS](state, _import) {
        state.isFetchingImport = false;
        state.selectedImport = _import;
    },
    /**
     * @param {ImportsState} state
     */
    [FETCH_IMPORT_ERROR](state) {
        state.isFetchingImport = false;
    },
    /**
     * @param {ImportsState} state
     */
    [UNSELECT_IMPORT](state) {
        state.selectedImport = null;
    },
};

export const getters = {
    ...dateRangeGetters,
    /**
     * Return the ID corresponding to the route `:id`.
     * @return {string}
     */
    selectedItemId: (state, _getters, rootState) => {
        return rootState.route.params?.id;
    },
};

export const actions = {
    /**
     * Fetch import.
     */
    async [FETCH_IMPORT]({ commit, dispatch, getters: _getters, rootGetters }) {
        commit(FETCHING_IMPORT);
        try {
            if (!rootGetters[`${NS_METERS}/meters`]) {
                await dispatch(`${NS_METERS}/perimeter/${dataActionTypes.FETCH_ITEMS}`, undefined, {
                    root: true,
                });
            }

            const meters = rootGetters[`${NS_METERS}/meters`];
            const _import = await getImport({
                id: _getters.selectedItemId,
                customerCode: rootGetters.customerCode,
            });
            const importMeters = Object.entries(_import.meters).map(([id, count]) => {
                const meter = meters.find(({ id: meterId }) => meterId === id);
                return {
                    id,
                    name: meter?.name ?? id,
                    deleted: !meter,
                    count,
                    firstDate: _import.firstDate,
                    lastDate: _import.lastDate,
                };
            });
            commit(FETCH_IMPORT_SUCCESS, { ..._import, meters: importMeters });
        } catch (error) {
            commit(FETCH_IMPORT_ERROR);
        }
    },
    /**
     * Navigate to import creation form
     *
     * @param {Object} context
     * @param {Object} payload
     */
    [GO_TO_CREATE_IMPORT](context, { router }) {
        router.push({
            name: 'imports-new',
        });
    },
    /**
     * Navigate to import
     */
    [GO_TO_IMPORT]({ getters: _getters }, { id, router }) {
        router.push({
            name: 'import',
            params: {
                id,
            },
            query: {
                startDate: _getters.startDate.toISOString(),
                endDate: _getters.endDate.toISOString(),
            },
        });
    },
    /**
     * Navigate to imports list.
     *
     * @param {Object} context
     * @param {Object} payload
     */
    [GO_TO_IMPORTS](context, { router, startDate, endDate }) {
        router.push({
            name: 'customer.imports',
            query: {
                startDate: startDate.toISOString(),
                endDate: endDate.toISOString(),
            },
        });
    },
    /**
     * Go back to the imports list from an import detail.
     */
    [GO_BACK_TO_IMPORTS]({ commit, state }, { router }) {
        router.push({
            name: 'customer.imports',
            query: state.lastQueryParams,
        });
        commit(UNSELECT_IMPORT, null);
    },
};

export const NAMESPACE = 'imports';

export default {
    namespaced: true,
    state: _state,
    actions,
    mutations,
    getters,
    modules: {
        queryParams: queryParamsModule({
            namespaced: false,
            fields: ['startDate', 'endDate'],
        }),
    },
};
